import React from "react";

export const TrackConfigurator = () => {
  return (
    <div className="h-full">
      <div className="py-6 px-[96px] sticky top-0 bg-white">
        <h1 className="text-2xl font-medium font-gilroy-semi-bold">
          Track Configuration
        </h1>
      </div>
      <div className="max-w-[800px] ml-[96px]">
        <SingleTrack />
      </div>
    </div>
  );
};

function SingleTrack() {
  return (
    <div className="pb-5 border-b border-[#D1D1D1]">
      <div className="flex flex-col text-xs leading-5 ">
        <p className="font-gilroy-semi-bold">Name of Track</p>
        <p className="font-gilroy-medium">Lorem ipsum dolor sit amet.</p>
      </div>
    </div>
  );
}

export const ProfessionalSalutationOptions = [
  {
    value: "Ar",
    label: "Ar",
  },
  {
    value: "ID",
    label: "ID",
  },
  {
    value: "PMC",
    label: "PMC",
  },
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss",
    label: "Miss",
  },
];

export const partnerOptions = [
  {
    value: "CEO",
    label: "CEO",
  },
  {
    value: "Founder",
    label: "Founder",
  },
  {
    value: "Co-Founder",
    label: "Co-Founder",
  },
  {
    value: "Partners",
    label: "Partners",
  },
  {
    value: "Principal Architect",
    label: "Principal Architect",
  },
  {
    value: "President",
    label: "President",
  },
  {
    value: "Owner",
    label: "Owner",
  },
  {
    value: "COO",
    label: "COO",
  },
  {
    value: "Managing Director (MD)",
    label: "Managing Director (MD)",
  },
  {
    value: "Chief Design Officer (CDO)",
    label: "Chief Design Officer (CDO)",
  },
];

export const fieldOptions = [
  ...partnerOptions,
  // Architectural and Interior Design Team:
  {
    value: "Junior Architect",
    label: "Junior Architect",
  },
  {
    value: "Senior Architect",
    label: "Senior Architect",
  },
  {
    value: "Intern Architect",
    label: "Intern Architect",
  },
  {
    value: "Project Architect",
    label: "Project Architect",
  },
  {
    value: "Design Architect",
    label: "Design Architect",
  },
  {
    value: "Technical Architect",
    label: "Technical Architect",
  },
  {
    value: "Landscape Designer",
    label: "Landscape Designer",
  },
  {
    value: "Interior Designer",
    label: "Interior Designer",
  },
  {
    value: "Junior Interior Designer",
    label: "Junior Interior Designer",
  },
  {
    value: "Senior Interior Designer",
    label: "Senior Interior Designer",
  },
  {
    value: "Furniture Designer",
    label: "Furniture Designer",
  },
  {
    value: "Interior Decorator",
    label: "Interior Decorator",
  },
  {
    value: "Urban Planner",
    label: "Urban Planner",
  },
  {
    value: "Architectural Drafter",
    label: "Architectural Drafter",
  },

  // Project Management and Coordination Team:
  {
    value: "Project Head",
    label: "Project Head",
  },
  {
    value: "Project Manager",
    label: "Project Manager",
  },
  {
    value: "Assistant Project Manager",
    label: "Assistant Project Manager",
  },
  {
    value: "Project Coordinator",
    label: "Project Coordinator",
  },
  {
    value: "Construction Manager",
    label: "Construction Manager",
  },
  {
    value: "Site Supervisor",
    label: "Site Supervisor",
  },
  {
    value: "Site Engineer",
    label: "Site Engineer",
  },

  // Engineering and Technical Support:
  {
    value: "Structural Engineer",
    label: "Structural Engineer",
  },
  {
    value: "MEP Engineer (Mechanical, Electrical, Plumbing)",
    label: "MEP Engineer (Mechanical, Electrical, Plumbing)",
  },
  {
    value: "Civil Engineer",
    label: "Civil Engineer",
  },
  {
    value: "BIM Specialist",
    label: "BIM Specialist",
  },
  {
    value: "Sustainability Consultant",
    label: "Sustainability Consultant",
  },
  {
    value: "LEED or Green Building Consultant",
    label: "LEED or Green Building Consultant",
  },

  // Visualization and Graphics Team:
  {
    value: "3D Visualizer",
    label: "3D Visualizer",
  },
  {
    value: "Rendering Specialist",
    label: "Rendering Specialist",
  },
  {
    value: "Graphic Designer",
    label: "Graphic Designer",
  },

  // Business Development and Client Relations:
  {
    value: "Client Relations Manager",
    label: "Client Relations Manager",
  },
  {
    value: "Business Development Manager",
    label: "Business Development Manager",
  },
  {
    value: "Marketing Specialist",
    label: "Marketing Specialist",
  },
  {
    value: "Sales Representative",
    label: "Sales Representative",
  },
];

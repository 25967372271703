import React, { useState } from "react";
import { VariantDataProps, VariantTrackTypeProps } from "../../../../types";
import DeleteIcon from "../../../../assets/svg/trash.svg";
import EditIcon from "../../../../assets/svg/pen.svg";
import CopyIcon from "../../../../assets/svg/copyIcon.svg";
import PasswordIcon from "../../../../assets/PasswordIcon";
import Button from "../../../../components/Button";
import Modal from "../../../../components/common/Modal";

import TickMark from "../../../../assets/TickMark";

import CrossIcon from "../../../../assets/CrossIcon";

// TRACK_TYPE Option Schema
// {
//   "name": "Option #1",
//   "id": "ccf6f29d-6b8c-46fc-ba0a-4d0d6ae48ddd",
//   "to_delete": true,
//   "position": 1,
//   "image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "product_image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "price": 19.99,
//   "allow_a": true,
//   "allow_b": true,
//   "allow_c": true,
//   "length_formula": "2*(a*b*c)",
//   "allow_file_upload": false,
// "description": "This is description.",
// "driver_cost": 100,
// "driver_length": 30,
// }

type VariantTrackTypeOptionCardProps = {
  el: VariantTrackTypeProps;
  index?: number;
  variantData?: VariantDataProps;
  setVariantData?: (data: VariantDataProps) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSeletedOption?: React.Dispatch<React.SetStateAction<any>>;
  nameExtraClass?: string;
};
const TrackTypeOptionCard = ({
  el,
  index,
  variantData,
  setVariantData,
  setOpenModal,
  setSeletedOption,
  nameExtraClass = "",
}: VariantTrackTypeOptionCardProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(null);

  const handleEdit = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "edit" });
  };
  const handleCopy = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "copy" });
  };

  const handleDelete = (elid: any) => {
    if (!variantData) return;
    // Delete Option Functionality
    const deletedOptions = variantData.options.find((each) => each.id === elid);
    // @ts-ignore
    if (deletedOptions?.added) {
      const updatedOptions = variantData.options.filter(
        (each) => each.id !== elid
      );
      setVariantData?.({ ...variantData, options: updatedOptions });
    } else {
      if (deletedOptions) {
        deletedOptions.to_delete = true;
        const updatedOptions = variantData.options.filter(
          (each) => each.id !== elid
        );
        setVariantData?.({
          ...variantData,
          options: [...updatedOptions, deletedOptions],
        });
      }
    }
    setShowDeleteModal(null);
  };
  const handleImageClick = (img: any) => {
    if (img) {
      window.open(`${process.env.REACT_APP_BUCKET}/${img}`, "_blank"); //eslint-disable-line
    }
  };
  return (
    <>
      <div className="border-2 text-md leading-tight w-full h-full flex flex-col py-4 gap-2">
        <div className="flex flex-col justify-between px-4 gap-3">
          <div className="flex justify-between gap-3">
            <p className={`${nameExtraClass}`}>
              Name: <span>{el.name ? el.name : "-"}</span>
            </p>

            {variantData && (
              <div className="flex gap-2">
                <img
                  src={CopyIcon}
                  alt="CopyIcon"
                  onClick={() => handleCopy(el.id)}
                  className="cursor-pointer h-4 w-4"
                />

                <img
                  src={EditIcon}
                  alt="EditIcon"
                  onClick={() => handleEdit(el.id)}
                  className="cursor-pointer h-4 w-4"
                />
                <img
                  src={DeleteIcon}
                  alt="DeleteIcon"
                  onClick={() => setShowDeleteModal(el.id)}
                  className="cursor-pointer h-4 w-4"
                />
              </div>
            )}
          </div>

          <div className="flex justify-start flex-wrap gap-3">
            <div className="flex items-baseline gap-2 flex-1">
              {el.allow_a ? (
                <TickMark color="#D4A15E" />
              ) : (
                <CrossIcon color="#D4A15E" width="10" />
              )}{" "}
              <span className="whitespace-nowrap">Allow length A</span>
            </div>
            <div className="flex items-baseline gap-2 flex-1">
              {el.allow_b ? (
                <TickMark color="#D4A15E" />
              ) : (
                <CrossIcon color="#D4A15E" width="10" />
              )}{" "}
              <span className="whitespace-nowrap">Allow length B</span>
            </div>
            <div className="flex items-baseline gap-2 flex-1">
              {el.allow_c ? (
                <TickMark color="#D4A15E" />
              ) : (
                <CrossIcon color="#D4A15E" width="10" />
              )}{" "}
              <span className="whitespace-nowrap">Allow length C</span>
            </div>
            <div className="flex items-baseline gap-2 flex-1">
              {el.allow_file_upload ? (
                <TickMark color="#D4A15E" />
              ) : (
                <CrossIcon color="#D4A15E" width="10" />
              )}{" "}
              <span className="whitespace-nowrap">Allow file upload</span>
            </div>
          </div>

          <div className="flex gap-2">
            <p>Length Formula:</p>
            <p>{el?.length_formula ? el?.length_formula : "-"}</p>
          </div>
          <div className="flex gap-2">
            <p>Price (per foot):</p>
            <p>{el.price ? `Rs ${el.price}` : "-"}</p>
          </div>

          <p className="flex gap-x-1 flex-wrap">
            <span>Controller price of Rs.</span>
            <span>{el?.driver_cost >= 0 ? el?.driver_cost : "-"}</span>
            <span>per</span>
            <span>{el?.driver_length >= 0 ? el?.driver_length : "-"}</span>
            <span>feet.</span>
          </p>

          <hr className="border-[1px] border-[#F4F2EE] leading-3"></hr>

          <div className="flex justify-between">
            <div className="flex items-center gap-2 grow">
              <p>Image: </p>
              {el.image ? (
                <div
                  className={`bg-[#F4F4F4] p-2 py-1 flex items-center gap-4  ${
                    !el.image
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  onClick={() => handleImageClick(el?.image)}
                >
                  <p>{el?.image?.slice(-8)}</p>
                  <span>
                    <PasswordIcon />
                  </span>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <Modal
          top
          header="Delete Option"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(showDeleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setShowDeleteModal(null);
                }}
              />
            </div>
          }
          handleCancel={() => setShowDeleteModal(null)}
        >
          <p className="text-left">
            Are you sure you want to delete the option?
          </p>
        </Modal>
      )}
    </>
  );
};

export default TrackTypeOptionCard;

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import InputField from "../../../components/InputField";
import Dropdown from "./Dropdown";
import FixedDetail from "./FixedDetail";
import { validatePrice } from "../../../utils/validations";
import { useParams } from "react-router-dom";
import ImageUpload from "../../../components/ImageUpload";

import { useRecoilState, useSetRecoilState } from "recoil";
import {
  productBrandAtom,
  productCategoryAtom,
  productSeriesAtom,
  titleAtom,
} from "../../../atom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import _debounce from "lodash/debounce";
import TextArea from "../../../components/TextArea";
import SelectField2 from "../../../components/SelectedField2";

type FormType = {
  setForm: Dispatch<SetStateAction<any>>;
  form: any;
  setErrors: Dispatch<SetStateAction<any>>;
  errors: any;
  editIds: any;
  loading?: boolean;
  codeRef?: any;
  nameRef?: any;
  descriptionRef?: any;
  primaryImageRef?: any;
  brandRef?: any;
  seriesRef?: any;
  categoryRef?: any;
  basePriceRef?: any;
  fixedDetailsRef?: any;
  positionRef?: any;
};

const Form = ({
  setForm,
  form,
  setErrors,
  errors,
  editIds,
  loading = false,
  codeRef,
  nameRef,
  descriptionRef,
  primaryImageRef,
  brandRef,
  seriesRef,
  categoryRef,
  basePriceRef,
  fixedDetailsRef,
  positionRef,
}: FormType) => {
  const [imageName, setImageName] = useState("");
  const [showUploadDiv, setShowUploadDiv] = useState(true);
  const [seriesList, setSeriesList] = useRecoilState(productSeriesAtom);
  const [categoryList, setCategoryList] = useRecoilState(productCategoryAtom);
  const [brandList, setBrandList] = useRecoilState(productBrandAtom);
  const [categoryInput, setCategoryInput] = useState("");
  const [seriesInput, setSeriesInput] = useState("");
  const [brandInput, setBrandInput] = useState("");
  const { id } = useParams();

  const [brandValue, setBrandValue] = useState<string>();
  const [seriesValue, setSeriesValue] = useState<string>();
  const [categoryValue, setCategoryValue] = useState<string>();

  const handleBlur = (searchVal: any, key: string, list: any) => {
    const lowerCaseSearchVal = searchVal?.toLowerCase();
    const newList: any[] = [];
    list?.forEach((item: any) => {
      const currentVal = item?.name || item?.full_name;
      const lowercaseVal = currentVal?.toLowerCase();
      newList.push(lowercaseVal);
    });

    if (lowerCaseSearchVal !== "" && !newList.includes(lowerCaseSearchVal)) {
      setErrors((prev: any) => ({
        ...prev,
        [key]: "Please select a valid option!",
      }));
    } else if (list.includes(lowerCaseSearchVal)) {
      delete errors[key];
    }
  };

  const handleCancelFile = () => {
    setShowUploadDiv(true);
    setForm((prevData: any) => ({
      ...prevData,
      image: { title: "", imageUrl: "" },
    }));
    setImageName("");
  };

  const handleAddProperty = () => {
    const newProperty = { assign: "" };
    setForm((prevData: any) => ({
      ...prevData,
      assigned: [...prevData.assigned, newProperty],
    }));
  };

  const handlePropertyChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (index === form.assigned.length - 1) {
      handleAddProperty();
    }
    const { name, value } = e.target;
    setForm((prevData: any) => {
      const updatedAssigned = [...prevData.assigned];
      updatedAssigned[index] = { ...updatedAssigned[index], [name]: value };
      return { ...prevData, assigned: updatedAssigned };
    });
  };

  const handleDeleteProperty = (index: number) => {
    if (form.assigned.length === 1) {
      return;
    }
    setForm((prevData: any) => {
      const updatedAssigned = [...prevData.assigned];
      updatedAssigned.splice(index, 1);
      return { ...prevData, assigned: updatedAssigned };
    });
  };

  const fetchSeries = async () => {
    const { data: response } = await protectedAxiosInstance.get(
      `/admin/products/series`,
      {
        params: { length: 100, search: "" },
      }
    );

    if (response.data) {
      setSeriesList(response.data.series);
    }
  };

  const fetchCategories = async () => {
    const { data: response } = await protectedAxiosInstance.get(
      `/admin/products/category`,
      {
        params: { length: 100, search: "" },
      }
    );

    if (response.data) {
      setCategoryList(response.data.category);
    }
  };

  const fetchBrand = async () => {
    const { data: response } = await protectedAxiosInstance.get(
      `/admin/products/brand`,
      {
        params: { length: 100, search: "" },
      }
    );
    if (response.data) {
      setBrandList(response.data.brand);
    }
  };

  useEffect(() => {
    if (seriesList?.length === 0) {
      fetchSeries();
    }
    if (categoryList?.length === 0) {
      fetchCategories();
    }
    if (brandList?.length === 0) {
      fetchBrand();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="flex gap-4">
        <InputField
          disabled={loading}
          targetRef={codeRef}
          label="Code:"
          placeholder="Code"
          value={form.code}
          onChange={(value) => {
            if (errors.code) {
              delete errors.code;
              setErrors({ ...errors });
            }
            setForm({ ...form, code: value?.toUpperCase() });
          }}
          hint={errors?.code}
          maxLength={10}
        />

        <InputField
          disabled={loading}
          targetRef={positionRef}
          label="Position:"
          placeholder="Position"
          value={form.position}
          onChange={(value) => {
            if (errors.position) {
              delete errors.position;
              setErrors({ ...errors });
            }
            setForm({ ...form, position: value });
          }}
          hint={errors?.position}
          validate={validatePrice}
        />
      </div>

      <InputField
        className="my-7"
        disabled={loading}
        required
        targetRef={nameRef}
        label="Product name:"
        placeholder="Product name"
        value={form.name}
        onChange={(value) => {
          if (errors.name) {
            delete errors.name;
            setErrors({ ...errors });
          }
          setForm({ ...form, name: value });
        }}
        hint={errors?.name}
      />

      <TextArea
        className="my-7"
        label="Description:"
        targetRef={descriptionRef}
        required
        disabled={loading}
        value={form.description}
        onChange={(e) => {
          if (errors.description) {
            delete errors.description;
            setErrors(structuredClone(errors));
          }
          setForm({ ...form, description: e });
        }}
        placeholder="Description"
        rows={5}
        hint={errors?.description}
      />

      {/* Upload img file */}
      <ImageUpload
        targetRef={primaryImageRef}
        label="Upload Product Image:"
        required
        disabled={loading}
        isMulti
        kind="PRODUCT"
        setter={(values: any) => {
          form.images = values;
          setForm(structuredClone(form));
        }}
        imageList={form.images}
        primary={form.primary_image}
        primarySetter={(value) => {
          form.primary_image = value;
          setForm(structuredClone(form));
        }}
        errors={[...(errors?.images || []), ...(errors?.primary_image || [])]}
        removeError={() => {
          if (errors.images?.length) delete errors?.images;
          if (errors.primary_image) delete errors?.primary_image;
          setErrors(structuredClone(errors));
        }}
      />

      <div className="flex flex-col gap-4 pt-4 ">
        <SelectField2
          targetRef={brandRef}
          value={form.brand_id}
          label="Select Brand"
          required
          options={brandList.map((el) => ({ label: el.name, value: el.id }))}
          onChange={(e) => {
            setForm((prev: any) => ({ ...prev, brand_id: e }));
            setErrors((prev: any) => ({ ...prev, brand_id: null }));
          }}
          hint={errors?.brand_id}
          searchPlaceHolder={"Search brand"}
          disabled={loading}
        />
        <SelectField2
          targetRef={seriesRef}
          value={form.series_id}
          label="Select Series"
          required
          options={seriesList.map((el) => ({
            label: el.full_name,
            value: el.id,
          }))}
          onChange={(e) => {
            setForm((prev: any) => ({ ...prev, series_id: e }));
            setErrors((prev: any) => ({ ...prev, series_id: null }));
          }}
          hint={errors?.series_id}
          searchPlaceHolder={"Search series"}
          disabled={loading}
        />
        <SelectField2
          targetRef={categoryRef}
          value={form.category_id}
          label="Select Category"
          required
          options={categoryList.map((el) => ({
            label: el.name,
            value: el.id,
          }))}
          onChange={(e) => {
            setForm((prev: any) => ({ ...prev, category_id: e }));
            setErrors((prev: any) => ({ ...prev, category_id: null }));
          }}
          hint={errors?.category_id}
          searchPlaceHolder={"Search category"}
          disabled={loading}
        />
      </div>

      <InputField
        targetRef={basePriceRef}
        disabled={loading}
        label="Price:"
        required
        placeholder="Price"
        type="price"
        value={form.base_price}
        onChange={(e: any) => {
          if (e > 99999999) {
            setErrors((prevError: any) => ({
              ...prevError,
              base_price: ["Price should be less than or equal to 99999999."],
            }));
          } else {
            setForm((prevData: any) => ({
              ...prevData,
              base_price: e,
            }));
            setErrors((prevError: any) => ({
              ...prevError,
              base_price: [],
            }));
          }
        }}
        className="my-5"
        validate={validatePrice}
        hint={errors?.base_price}
      />
      <div ref={fixedDetailsRef}>
        <FixedDetail form={form} setForm={setForm} />
      </div>
    </div>
  );
};

export default Form;

import DashboardIcon from "../assets/svg/dashboard.svg";
import ProjectIcon from "../assets/svg/note.svg";
import ProductIcon from "../assets/svg/cube.svg";
import DesignconsultIcon from "../assets/svg/penunderscore.svg";
import OrderIcon from "../assets/svg/data.svg";
import CustomerIcon from "../assets/svg/customer.svg";
import UserIcon from "../assets/svg/user.svg";

/* eslint-disable */
// 1. Dashboard
// 2. Orders
//     - Orders
//     - Create new Order
//     - Drafts
// 3. Customers
//     - Users & Professionals
//     - Create Customer
// 4. User Management
//     - User List
//     - Create User
// 5. Product Management
//     - Product List
//     - Create Product
//     - Variant List
//     - Create Variant
//     - Category List
//     - Create new Category
//     - Series List
//     - Create new Series
// 6. Project Management
//     - Project List
//     - Create new Project
//     - Drafts
// 7. Design Consultant
//     - Consultancy List
//     - Create Consultant
/* eslint-disable */

export interface NavItem {
  icon: string;
  heading?: string;
  title: string | undefined;
  to: string;
  cc?: string;
  ce?: string;
  cf?: string;
  cg?: string;
  ch?: string;
  ci?: string;
  cj?: string;
  ck?: string;
  cl?: string;
  subItems?: {
    title: string | undefined;
    to: string;
  }[];
}

export const navItems: NavItem[] = [
  {
    icon: DashboardIcon,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: OrderIcon,
    title: "Order Management",
    to: "/orders",
    cc: "/orders/create",
    subItems: [
      {
        title: "All Orders",
        to: "/orders",
      },
      {
        title: "Place new Order",
        to: "/orders/create",
      },
    ],
  },
  {
    icon: CustomerIcon,
    title: "Customer Management",
    to: "/customers",
    cc: "/customers/professionals/employee",
    ce: "/customers/professionals/company",
    cf: "/customers/professionals/pending-requests",
    subItems: [
      {
        title: "Customers",
        to: "/customers",
      },
      {
        title: "Employee",
        to: "/customers/professionals/employee",
      },
      {
        title: "Company",
        to: "/customers/professionals/company",
      },
      {
        title: "Pending",
        to: "/customers/professionals/pending-requests",
      },
    ],
  },

  {
    icon: UserIcon,
    title: "User Management",
    to: "/users",
    cc: "/users/create",
    subItems: [
      {
        title: "User List ",
        to: "/users",
      },
      {
        title: "Create User",
        to: "/users/create",
      },
    ],
  },
  {
    icon: ProductIcon,
    title: "Product Management",
    to: "/products",
    cc: "/products/create",
    ce: "/products/variant-templates",
    cf: "/products/variant-templates/create",
    cg: "/products/categories",
    ch: "/products/categories/create",
    ci: "/products/series",
    cj: "/products/series/create",
    ck: "/products/brands",
    cl: "/products/brands/create",
    subItems: [
      {
        title: "Product List",
        to: "/products",
      },
      // {
      //   title: " Create Product",
      //   to: "/create-product",
      // },
      {
        title: "Variant Template List",
        to: "/products/variant-templates",
      },
      // {
      //   title: "Create Variant",
      //   to: "/create-variant",
      // },
      {
        title: "Category List",
        to: "/products/categories",
      },
      // {
      //   title: " Create new Category",
      //   to: "/create-new-category",
      // },
      {
        title: " Series List",
        to: "/products/series",
      },
      {
        title: "Brands List",
        to: "/products/brands",
      },
      {
        title: "Track Configuration",
        to: "/products/track-configurator",
      },
    ],
  },
  {
    icon: ProjectIcon,
    title: "Project Management",
    to: "/projects",
    cc: "/projects/create",
    ce: "/project-drafts",
    subItems: [
      {
        title: "Project List",
        to: "/projects",
      },
      {
        title: "Create new Project",
        to: "/projects/create",
      },
      {
        title: "Drafts",
        to: "/project-drafts",
      },
    ],
  },
  {
    icon: DesignconsultIcon,
    title: "Design Consultant",
    to: "/consultancy-list",
    cc: "/create-consultant",
    subItems: [
      {
        title: "Consultancy List",
        to: "/consultancy-list",
      },
      {
        title: "Create Consultant",
        to: "/create-consultant",
      },
    ],
  },
];

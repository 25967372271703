import React, { useLayoutEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./style/fonts.css";
import BaseOutlet from "./navigationOutlet/Base";
import Dashboard from "./pages/Dashboard/Dashboard";
import OrderList from "./pages/OrderManagment/OrderList";
import CreateOrder from "./pages/OrderManagment/CreateOrder";
import UserList from "./pages/UserManagment/UserList";
import CreateUser from "./pages/UserManagment/CreateUser";
import VariantList from "./pages/ProductManagment/VariantList";
import CategoryList from "./pages/ProductManagment/CategoryList";
import CreateCategory from "./pages/ProductManagment/CreateCategory";
import SeriesList from "./pages/ProductManagment/SeriesList";
import ProjectList from "./pages/ProjectManagment/ProjectList";
import ProjectDrafts from "./pages/ProjectManagment/ProjectDrafts";
import ProductList from "./pages/ProductManagment/ProductList";
import CreateConsultant from "./pages/DesignConsultancy/CreateConsultant";
import ConsultancyList from "./pages/DesignConsultancy/ConsultancyList";
import ConsultantDetail from "./pages/ConsultantDetail";
import EditCategory from "./pages/ProductManagment/categorycomponents/EditForm";
import Customers from "./pages/Customer/Customers";
import SingleProjectDetails from "./pages/ProjectManagment/SingleProjectDetails";
import SingleOrderDetails from "./pages/OrderManagment/SingleOrderDetails";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/PrivateRoute";
import EditAdminUser from "./pages/UserManagment/EditAdminUser";
import ForgotPassword from "./pages/ForgotPassword";
import Test from "./pages/Test/Test";
import BrandsList from "./pages/ProductManagment/BrandList";
import CreateEditProduct from "./pages/ProductManagment/CreateEditProduct";
import CreateEditBrand from "./pages/ProductManagment/CreateEditBrand";
import CustomersTable from "./pages/Customer/pages/CustomersTable";
import CompanyTable from "./pages/Customer/pages/CompanyTable";
import PendingTable from "./pages/Customer/pages/PendingTable";
import CreateEditProfessional from "./pages/Customer/pages/CreateEditProfessional";
import CreateEditVariant from "./pages/ProductManagment/CreateEditVariant";
import EmployeeTable from "./pages/Customer/pages/EmployeeTable";
import CreateEditSeries from "./pages/ProductManagment/CreateEditSeries";
import CustomerDetails from "./pages/Customer/pages/CustomerDetails";
import EmployeeDetail from "./pages/ProfessionalDetail/EmployeeDetail";
import CompanyDetail from "./pages/ProfessionalDetail/CompanyDetail";
import NonPrivateRoute from "./components/NonPrivateRoute";
import CreateEditCustomer from "./pages/Customer/pages/CreateEditCustomer";
import Notfound from "./pages/404/components/Notfound";
import CreateEditProject from "./pages/ProjectManagment/CreateEditProject";
import { TrackConfigurator } from "./pages/ProductManagment/TrackConfigurator";

const App = () => {
  const [showSmallScreenMessage, setShowSmallScreenMessage] = useState(false);
  const [, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    const newWindowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    setWindowSize(newWindowSize);

    // Conditionally update the state based on screen size
    const isSmallScreen =
      newWindowSize.width < 720 ||
      newWindowSize.width < newWindowSize.height * 1.2;
    setShowSmallScreenMessage(isSmallScreen);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <>
      <div
        className={`font-custom !tracking-wider relative ${
          showSmallScreenMessage ? "hidden" : "block"
        }`}
      >
        <Routes>
          {/* Login */}
          <Route
            path="/login"
            element={
              <NonPrivateRoute>
                <Login />
              </NonPrivateRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <NonPrivateRoute>
                <ForgotPassword />
              </NonPrivateRoute>
            }
          />
          <Route path="/test" element={<Test />} />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <BaseOutlet />
              </PrivateRoute>
            }
          >
            {/* Dashboard */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Order Managment */}
            <Route path="/orders" element={<OrderList />} />
            <Route path="/orders/:id" element={<SingleOrderDetails />} />
            <Route path="/orders/create" element={<CreateOrder />} />

            {/* Customer Managment */}
            <Route
              path="/customers"
              element={
                <Customers>
                  <CustomersTable />
                </Customers>
              }
            />
            <Route
              path="/customers/professionals/company"
              element={
                <Customers>
                  <CompanyTable />
                </Customers>
              }
            />
            <Route
              path="/customers/professionals/employee"
              element={
                <Customers>
                  <EmployeeTable />
                </Customers>
              }
            />
            <Route
              path="/customers/professionals/pending-requests"
              element={
                <Customers>
                  <PendingTable />
                </Customers>
              }
            />

            <Route path="/customers/create" element={<CreateEditCustomer />} />
            <Route
              path="/customers/:id/edit"
              element={<CreateEditCustomer />}
            />
            <Route path="/customers/:id" element={<CustomerDetails />} />
            <Route
              path="/customers/professionals/employee/:id"
              element={<EmployeeDetail />}
            />
            <Route
              path="/customers/professionals/company/:id"
              element={<CompanyDetail />}
            />
            <Route
              path="/customers/professionals/:id/edit"
              element={<CreateEditProfessional />}
            />

            {/* User Managment */}
            <Route path="/users" element={<UserList />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/users/:id" element={<EditAdminUser />} />

            {/* Product Managment */}
            <Route path="/products" element={<ProductList />} />
            <Route
              path="/products/track-configurator"
              element={<TrackConfigurator />}
            />
            <Route path="/products/create" element={<CreateEditProduct />} />
            <Route path="/products/:id" element={<CreateEditProduct />} />
            <Route
              path="/products/variant-templates"
              element={<VariantList />}
            />
            <Route
              path="/products/variant-templates/create"
              element={<CreateEditVariant />}
            />
            <Route
              path="/products/variant-templates/:id"
              element={<CreateEditVariant />}
            />
            <Route path="/products/categories" element={<CategoryList />} />
            <Route
              path="/products/categories/create"
              element={<CreateCategory />}
            />
            <Route path="/products/categories/:id" element={<EditCategory />} />
            <Route path="/products/series" element={<SeriesList />} />
            <Route
              path="/products/series/create"
              element={<CreateEditSeries />}
            />
            <Route path="/products/series/:id" element={<CreateEditSeries />} />

            <Route path="/products/brands" element={<BrandsList />} />
            <Route
              path="/products/brands/create"
              element={<CreateEditBrand />}
            />
            <Route path="/products/brands/:id" element={<CreateEditBrand />} />

            {/* Project Managment */}
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/projects/:id" element={<SingleProjectDetails />} />
            <Route path="/projects/create" element={<CreateEditProject />} />
            <Route path="/projects/:id/edit" element={<CreateEditProject />} />
            <Route path="/project-drafts" element={<ProjectDrafts />} />

            {/* Design Consult */}
            <Route path="/consultancy-list" element={<ConsultancyList />} />
            <Route path="/create-consultant" element={<CreateConsultant />} />
            <Route
              path="/consultancy-list/:id"
              element={<ConsultantDetail />}
            />

            {/* Page not found */}
            <Route path="/*" element={<Notfound />} />

            {/* Redirect routes in home page */}
            <Route path="" element={<Navigate to="/dashboard" />} />
          </Route>
        </Routes>
      </div>

      <div
        className={`${
          showSmallScreenMessage ? "flex z-[1000] relative bg-white" : "hidden"
        } flex-col h-[100vh] w-[100vw] justify-center items-center text-2xl `}
      >
        <div className="text-center flex flex-col justify-center items-center ">
          <p className="font-gilroy-bold text-2xl border-2 border-pot-yellow  ease-in-out transition rounded-md bg-pot-yellow text-white stroke-white hover:bg-pot-grey5 hover:border-pot-grey5 px-2.5 py-1.5 ">
            Screen size not supported.
          </p>
          {/* <Button
            label="Bypass for now (For Developers)"
            onClick={() => {
              // setWindowSize({ width: 1024, height: 848 });
              setShowSmallScreenMessage(false);

              // if (windowSize.width > 1024 && windowSize.height > 1024) {
              //   const resizeEvent = new Event("resize");
              //   window.dispatchEvent(resizeEvent);
              // }
            }}
          /> */}
        </div>
      </div>
    </>
  );
};

export default App;

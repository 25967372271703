import { useEffect, useMemo, useRef, useState } from "react";

import Title from "../../../components/Title";
import { Link, useParams } from "react-router-dom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import AuthInputField from "../../../components/AuthInputField";
import InputField from "../../../components/InputField";
import Checkbox from "../../../components/Checkbox";
import {
  checkBankAccountNumber,
  checkGST,
  checkIFSC,
  checkPAN,
  checkPincode,
  validateHTTPURL,
  validateSpaceNotAllowed,
  validateTextNumberHyphen,
} from "../../../utils/validations";
import SelectField2 from "../../../components/SelectedField2";
import TextArea from "../../../components/TextArea";
import ProfileImageUpload from "../../../components/ProfileImageUpload";
import Button from "../../../components/Button";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import PlusIcon from "../../../assets/PlusIcon";
import ExperienceCard from "../CreateEditProfessionalComponents/ExperienceCard";
import CrossIcon from "../../../assets/CrossIcon";
import FacebookIcon from "../../../assets/svg-tsx/FacebookIcon";
import XIcon from "../../../assets/svg-tsx/XIcon";
import InstagramIcon from "../../../assets/svg-tsx/InstagramIcon";
import LinkedInIcon from "../../../assets/svg-tsx/LinkedInIcon";
import WebsiteIcon from "../../../assets/svg-tsx/WebsiteIcon";
import { parsePhoneNumber } from "libphonenumber-js";
import UploadFile from "../../../components/UploadFile";
import { toast } from "react-toastify";
import CreateEditExperience from "../CreateEditProfessionalComponents/CreateEditExperience";
import { getGstDetails } from "../../../api/gst";
import GSTPopup from "../components/GSTPopup";
import ProfessionalHistory from "./ProfessionalHistory";
import ReSubmitForm from "../components/ReSubmitForm";
import {
  getProfessional,
  getProfessionalList,
  getProfessionalStatusHistory,
} from "../../../api/professional";
import Modal from "../../../components/common/Modal";
import EmployeeIcon from "../../../assets/svg-tsx/EmployeeIcon";
import CompanyIcon from "../../../assets/svg-tsx/CompanyIcon";
import Notfound from "../../404/components/Notfound";
import useOnClickOutside from "../../../hooks/useClickOutside";
import Label from "../../../components/Label";
import {
  partnerOptions,
  ProfessionalSalutationOptions,
} from "../../../utils/data";
import {
  removeExtraSpaces,
  removeExtraSpacesfromObject,
} from "../../../utils/helper";

const CreateEditProfessional = () => {
  const { id: professionalID } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [professionOptions, setProfessionOptions] = useState([]);
  const [companyCategoriesOptions, setCompanyCategoriesOptions] = useState([]);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);
  const [businessTypeOptions, setBusinessTypeOptions] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [professionalType, setProfessionalType] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [openGSTModal, setGSTOpenModal] = useState<boolean>(false);
  const [gstDetails, setGstDetails] = useState<any>();
  const [openSubmitModal, setOpenSubmitModal] = useState<string | null>(null);
  const [submitData, setSubmitData] = useState<any>(null);
  const [notFound, setNotFound] = useState(false);

  // auto slug states
  const [individualSlugFound, setIndividualSlugFound] = useState(false);
  const [individualSlugEdited, setIndividualSlugEdited] = useState(false);
  const [companySlugFound, setCompanySlugFound] = useState(false);
  const [companySlugEdited, setCompanySlugEdited] = useState(false);

  // refs for targeting
  const individual_ref = useRef<any>(null);
  const company_ref = useRef<any>(null);
  const work_experience_ref = useRef<any>(null);

  const individual_salutation_ref = useRef<any>(null);
  const individual_name_ref = useRef<any>(null);
  const individual_profession_ref = useRef<any>(null);
  const individual_practice_location_ref = useRef<any>(null);
  const individual_about_ref = useRef<any>(null);
  const individual_profile_picture_ref = useRef<any>(null);
  const individual_slug_ref = useRef<any>(null);

  const company_name_ref = useRef<any>(null);
  const company_category_ref = useRef<any>(null);
  const company_practice_location_ref = useRef<any>(null);
  const company_establishment_year_ref = useRef<any>(null);
  const company_practice_areas_ref = useRef<any>(null);
  const company_about_ref = useRef<any>(null);
  const company_profile_picture_ref = useRef<any>(null);
  const company_slug_ref = useRef<any>(null);
  const company_owner_title_ref = useRef<any>(null);
  const company_social_fb_ref = useRef<any>(null);
  const company_social_x_ref = useRef<any>(null);
  const company_social_insta_ref = useRef<any>(null);
  const company_social_linkedin_ref = useRef<any>(null);
  const company_social_website_ref = useRef<any>(null);
  const company_social_email_ref = useRef<any>(null);
  const company_social_phone_ref = useRef<any>(null);
  const company_biz_legal_name_ref = useRef<any>(null);
  const company_biz_type_ref = useRef<any>(null);
  const company_biz_about_ref = useRef<any>(null);
  const company_biz_pan_no_ref = useRef<any>(null);
  const company_biz_pan_name_ref = useRef<any>(null);
  const company_biz_gst_no_ref = useRef<any>(null);
  const company_biz_gst_doc_ref = useRef<any>(null);
  const company_biz_street1_ref = useRef<any>(null);
  const company_biz_street2_ref = useRef<any>(null);
  const company_biz_city_ref = useRef<any>(null);
  const company_biz_state_ref = useRef<any>(null);
  const company_biz_zipcode_ref = useRef<any>(null);
  const company_bank_beneficiary_ref = useRef<any>(null);
  const company_bank_ifsc_ref = useRef<any>(null);
  const company_bank_acc_no_ref = useRef<any>(null);
  const company_bank_cancel_chq_doc_ref = useRef<any>(null);

  const individualtargetRefs: any = {
    salutation: individual_salutation_ref,
    name: individual_name_ref,
    profession_id: individual_profession_ref,
    practice_location_city: individual_practice_location_ref,
    practice_location_state: individual_practice_location_ref,
    about: individual_about_ref,
    profile_picture: individual_profile_picture_ref,
    slug: individual_slug_ref,
  };

  const individualErrorOrder = [
    "salutation",
    "name",
    "profession_id",
    "practice_location_city",
    "practice_location_state",
    "about",
    "profile_picture",
    "slug",
  ];

  const companyTargetRefs: any = {
    name: company_name_ref,
    category_id: company_category_ref,
    practice_location_city: company_practice_location_ref,
    practice_location_state: company_practice_location_ref,
    establishment_year: company_establishment_year_ref,
    practice_areas: company_practice_areas_ref,
    about: company_about_ref,
    profile_picture: company_profile_picture_ref,
    slug: company_slug_ref,
    owner_title: company_owner_title_ref,
    social_fb: company_social_fb_ref,
    social_x: company_social_x_ref,
    social_insta: company_social_insta_ref,
    social_linkedin: company_social_linkedin_ref,
    social_website: company_social_website_ref,
    social_email: company_social_email_ref,
    social_phone: company_social_phone_ref,
    biz_legal_name: company_biz_legal_name_ref,
    biz_type_id: company_biz_type_ref,
    biz_about: company_biz_about_ref,
    biz_pan_no: company_biz_pan_no_ref,
    biz_pan_name: company_biz_pan_name_ref,
    biz_gst_no: company_biz_gst_no_ref,
    biz_gst_doc: company_biz_gst_doc_ref,
    biz_street1: company_biz_street1_ref,
    biz_street2: company_biz_street2_ref,
    biz_city: company_biz_city_ref,
    biz_state: company_biz_state_ref,
    biz_zipcode: company_biz_zipcode_ref,
    bank_beneficiary: company_bank_beneficiary_ref,
    bank_ifsc: company_bank_ifsc_ref,
    bank_acc_no: company_bank_acc_no_ref,
    bank_cancel_chq_doc: company_bank_cancel_chq_doc_ref,
  };

  const companyErrorOrder = [
    "name",
    "category_id",
    "practice_location_city",
    "practice_location_state",
    "establishment_year",
    "practice_areas",
    "about",
    "profile_picture",
    "slug",
    "owner_title",
    "social_fb",
    "social_x",
    "social_insta",
    "social_linkedin",
    "social_website",
    "social_email",
    "social_phone",
    "biz_legal_name",
    "biz_type_id",
    "biz_about",
    "biz_pan_no",
    "biz_pan_name",
    "biz_gst_no",
    "biz_gst_doc",
    "biz_street1",
    "biz_street2",
    "biz_city",
    "biz_state",
    "biz_zipcode",
    "bank_beneficiary",
    "bank_ifsc",
    "bank_acc_no",
    "bank_cancel_chq_doc",
  ];

  const handleBackendError = (error: any) => {
    const individualErrors = error.p_individual || {};
    const companyErrors = error.p_company || {};
    const generalErrors = error.general || {};

    if (Array.isArray(individualErrors)) {
      if (individual_ref && individual_ref.current) {
        individual_ref.current.scrollIntoView({ behavior: "smooth" });
        return;
      }
    }

    for (const key of individualErrorOrder) {
      if (key in individualErrors) {
        const ref = individualtargetRefs[key];
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
          return;
        }
      }
    }

    if (Array.isArray(generalErrors)) {
      if (generalErrors.some((err) => err.includes("job"))) {
        if (work_experience_ref && work_experience_ref.current) {
          work_experience_ref.current.scrollIntoView({ behavior: "smooth" });

          return;
        }
      }
    }

    if (Array.isArray(companyErrors)) {
      if (company_ref && company_ref.current) {
        company_ref.current.scrollIntoView({ behavior: "smooth" });
        return;
      }
    }

    for (const key of companyErrorOrder) {
      if (key in companyErrors) {
        const ref = companyTargetRefs[key];
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
          return;
        }
      }
    }
  };

  // profilelink
  const [links, setLinks] = useState<any>({});

  // Professional status history get api
  const [statusLoading, setStatusLoading] = useState(true);
  const [history, setHistory] = useState([]);

  const fetchStatusHistory = async () => {
    try {
      setStatusLoading(true);
      const response = await getProfessionalStatusHistory(professionalID);
      setHistory(response.data);
    } catch (error: any) {
      console.error("Error fetching professional status history:", error);
      if (error?.status === 404) {
        setNotFound(true);
      }
    } finally {
      setStatusLoading(false);
    }
  };
  useEffect(() => {
    fetchStatusHistory(); // Call the correct function
  }, [professionalID]); //eslint-disable-line

  const handleClose = () => {
    setGSTOpenModal(false);
  };

  const handleGSTDetails = async (gstin: any) => {
    await getGstDetails(gstin)
      .then((response) => {
        const { data } = response;
        // delete errorMessage.gst;
        setGstDetails(data);
      })
      .catch((error) => {
        // setErrorMessage({ gst: "Invalid GST" });
        toast.error("Invalid GST");
      });
  };
  const fetchProfessionalData = async () => {
    try {
      const res = await protectedAxiosInstance.get(
        `/admin/accounts/professional/${professionalID}`
      );
      const response = res.data.data;
      if (response?.p_individual?.slug) {
        setIndividualSlugFound(true);
      } else {
        setIndividualSlugFound(false);
      }
      if (response?.p_company?.slug) {
        setCompanySlugFound(true);
      } else {
        setCompanySlugFound(false);
      }
      if (response?.p_individual?.id) {
        delete response?.p_individual?.id;
      } else if (response?.p_company?.id) {
        delete response?.p_company?.id;
      }
      response?.p_company && response?.p_company?.id
        ? setProfessionalType("owner")
        : setProfessionalType("employee");

      if (response?.p_company?.social_phone) {
        const phoneNumber = response?.p_company?.social_phone;
        const parsedNumber = parsePhoneNumber(phoneNumber);
        setCountryCode("+" + parsedNumber?.countryCallingCode);
        response.p_company.social_phone = parsedNumber?.nationalNumber;
      }
      if (response?.p_individual?.id) {
        delete response?.p_individual?.id;
      } else if (response?.p_company?.id) {
        delete response?.p_company?.id;
      }

      if (response?.p_company?.social_fb) {
        response.p_company.social_fb = validateHTTPURL(
          response.p_company.social_fb
        );
        if (!response.p_company.social_fb) {
          delete response.p_company.social_fb;
        }
      }
      if (response?.p_company?.social_insta) {
        response.p_company.social_insta = validateHTTPURL(
          response.p_company.social_insta
        );
        if (!response.p_company.social_insta) {
          delete response.p_company.social_insta;
        }
      }
      if (response?.p_company?.social_linkedin) {
        response.p_company.social_linkedin = validateHTTPURL(
          response.p_company.social_linkedin
        );
        if (!response.p_company.social_linkedin) {
          delete response.p_company.social_linkedin;
        }
      }
      if (response?.p_company?.social_website) {
        response.p_company.social_website = validateHTTPURL(
          response.p_company.social_website
        );
        if (!response.p_company.social_website) {
          delete response.p_company.social_website;
        }
      }
      if (response?.p_company?.social_x) {
        response.p_company.social_x = validateHTTPURL(
          response.p_company.social_x
        );
        if (!response.p_company.social_x) {
          delete response.p_company.social_x;
        }
      }
      if (response?.p_company?.category?.id) {
        response.p_company.category_id = response.p_company.category.id;
        delete response.p_company.category;
      }
      if (response?.p_company?.biz_type?.id) {
        response.p_company.biz_type_id = response.p_company.biz_type.id;
        delete response.p_company.biz_type;
      }

      setLinks({
        employee_link: response?.p_individual?.slug,
        company_link: response?.p_company?.slug,
      });

      setFormData(response);
    } catch (error: any) {
      console.error("Error in updating professional form details", error);
      if (error?.status === 404) {
        setNotFound(true);
      }
    }
  };

  useEffect(() => {
    setPageLoading(true);

    const fetchProfessionalPromise = protectedAxiosInstance
      .get(`/admin/accounts/professional/${professionalID}`)
      .then((res) => {
        const response = res.data.data;
        if (response?.p_individual?.slug) {
          setIndividualSlugFound(true);
        } else {
          setIndividualSlugFound(false);
        }
        if (response?.p_company?.slug) {
          setCompanySlugFound(true);
        } else {
          setCompanySlugFound(false);
        }
        if (response?.p_individual?.id) {
          delete response?.p_individual?.id;
        } else if (response?.p_company?.id) {
          delete response?.p_company?.id;
        }
        response?.p_company && response?.p_company?.id
          ? setProfessionalType("owner")
          : setProfessionalType("employee");

        if (response?.p_company?.social_phone) {
          const phoneNumber = response?.p_company?.social_phone;
          const parsedNumber = parsePhoneNumber(phoneNumber);
          setCountryCode("+" + parsedNumber?.countryCallingCode);
          response.p_company.social_phone = parsedNumber?.nationalNumber;
        }
        if (response?.p_individual?.id) {
          delete response?.p_individual?.id;
        } else if (response?.p_company?.id) {
          delete response?.p_company?.id;
        }

        if (response?.p_company?.social_fb) {
          response.p_company.social_fb = validateHTTPURL(
            response.p_company.social_fb
          );
          if (!response.p_company.social_fb) {
            delete response.p_company.social_fb;
          }
        }
        if (response?.p_company?.social_insta) {
          response.p_company.social_insta = validateHTTPURL(
            response.p_company.social_insta
          );
          if (!response.p_company.social_insta) {
            delete response.p_company.social_insta;
          }
        }
        if (response?.p_company?.social_linkedin) {
          response.p_company.social_linkedin = validateHTTPURL(
            response.p_company.social_linkedin
          );
          if (!response.p_company.social_linkedin) {
            delete response.p_company.social_linkedin;
          }
        }
        if (response?.p_company?.social_website) {
          response.p_company.social_website = validateHTTPURL(
            response.p_company.social_website
          );
          if (!response.p_company.social_website) {
            delete response.p_company.social_website;
          }
        }
        if (response?.p_company?.social_x) {
          response.p_company.social_x = validateHTTPURL(
            response.p_company.social_x
          );
          if (!response.p_company.social_x) {
            delete response.p_company.social_x;
          }
        }
        if (response?.p_company?.category?.id) {
          response.p_company.category_id = response.p_company.category.id;
          delete response.p_company.category;
        }
        if (response?.p_company?.biz_type?.id) {
          response.p_company.biz_type_id = response.p_company.biz_type.id;
          delete response.p_company.biz_type;
        }

        setLinks({
          employee_link: response?.p_individual?.slug,
          company_link: response?.p_company?.slug,
        });

        setFormData(response);
      })
      .catch((error: any) => {
        console.error("Error in getting professional form details", error);
        if (error?.status === 404) {
          setNotFound(true);
        }
      });

    const professionPromise = protectedAxiosInstance
      .get(`/admin/accounts/profession`, {
        params: {
          length: 100,
          page: 1,
        },
      })
      .then((res) => {
        setProfessionOptions(res.data.data.profession);
      })
      .catch((error) => {
        console.error("Error in getting profession select data", error);
      });

    const companyCategoryPromise = protectedAxiosInstance
      .get(`/admin/accounts/company-category`, {
        params: {
          length: 100,
          page: 1,
        },
      })
      .then((res) => {
        const categories = res?.data?.data?.company_category?.map(
          (el: any) => ({
            value: el?.id,
            label: el?.name,
          })
        );
        setCompanyCategoriesOptions(categories);
      })
      .catch((error) => {
        console.error("Error in getting company category select data", error);
      });

    const practiceAreaPromise = protectedAxiosInstance
      .get(`/admin/accounts/practice-area`, {
        params: {
          length: 100,
          page: 1,
        },
      })
      .then((res) => {
        const practiceAreas = res?.data?.data?.practice_area.map((el: any) => ({
          value: el?.id,
          label: el?.name,
        }));

        setPracticeAreaOptions(practiceAreas);
      })
      .catch((error) => {
        console.error("Error in getting practice area select data", error);
      });

    const businessTypePromise = protectedAxiosInstance
      .get(`/admin/accounts/business-type`, {
        params: {
          length: 100,
          page: 1,
        },
      })
      .then((res) => {
        const businessTypes = res?.data?.data?.business_type.map((el: any) => ({
          value: el?.id,
          label: el?.name,
        }));
        setBusinessTypeOptions(businessTypes);
      })
      .catch((error) => {
        console.error("Error in getting business types select data", error);
      });

    Promise.all([
      fetchProfessionalPromise,
      professionPromise,
      companyCategoryPromise,
      practiceAreaPromise,
      businessTypePromise,
    ]).then(() => {
      setPageLoading(false);
    });
  }, [professionalID]);

  // p_individual?.practice_location_city,p_individual?.practice_location_state
  const stateList = useRecoilValue<any>(stateAtom);
  const [locationList, setLocationList] = useState<any>([]);
  const [locationPage, setLocationPage] = useState(1);
  const [locationTotalPage, setLocationTotalPage] = useState(0);
  const [locationSearch, setLocationSearch] = useState("");

  // Effect for handling locationSearch changes
  const itemsPerPage = 10;
  useEffect(() => {
    setLocationPage(1);
  }, [locationSearch]);

  useEffect(() => {
    const allCities =
      stateList
        ?.map((state: any) => {
          return state?.cities?.map((city: any) => ({
            value: `${city}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setLocationTotalPage(totalPage);

    if (locationPage === 1) {
      // Reset locationList if page is 1
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases
      const startIndex = (locationPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedList = [
        ...(locationList || []), // Preserve existing items
        ...filteredCities.slice(startIndex, endIndex),
      ];
      setLocationList(paginatedList);
    }
  }, [stateList, locationPage, locationSearch]);

  // p_company?.practice_location_city,p_company?.practice_location_state
  const [businessLocationList, setBusinessLocationList] = useState<any>([]);
  const [businessLocationPage, setBusinessLocationPage] = useState(1);
  const [businessLocationTotalPage, setBusinessLocationTotalPage] = useState(0);
  const [businessLocationSearch, setBusinessLocationSearch] = useState("");

  // Effect for handling businessLocationSearch changes
  useEffect(() => {
    setBusinessLocationPage(1);
  }, [businessLocationSearch]);

  useEffect(() => {
    const allCities =
      stateList
        ?.map((state: any) => {
          return state?.cities?.map((city: any) => ({
            value: `${city}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(businessLocationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setBusinessLocationTotalPage(totalPage);

    if (businessLocationPage === 1) {
      // Reset locationList if page is 1
      setBusinessLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases
      const startIndex = (businessLocationPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedList = [
        ...(businessLocationList || []), // Preserve existing items
        ...filteredCities.slice(startIndex, endIndex),
      ];
      setBusinessLocationList(paginatedList);
    }
  }, [stateList, businessLocationPage, businessLocationSearch]);

  // p_individual.profile_picture
  const profilePictureList = useMemo(
    () =>
      formData?.p_individual?.profile_picture
        ? [formData?.p_individual?.profile_picture]
        : [],
    [formData?.p_individual?.profile_picture]
  );

  // Work Experience
  const [workExperience, setWorkExperience] = useState([]);
  const fetchWorkExperience = async () => {
    if (professionalID) {
      try {
        const response = await protectedAxiosInstance.get(
          `/admin/accounts/professional/${professionalID}/workx`,
          {
            params: {
              length: 500,
            },
          }
        );
        setWorkExperience(response?.data?.data?.workx);
      } catch (error) {
        console.error("Error in getting work experience:", error);
      }
    }
  };

  useEffect(() => {
    if (professionalID) {
      fetchWorkExperience();
    }
  }, [professionalID]);

  const groupedWorkExperience: any =
    (workExperience &&
      Object?.values(
        workExperience?.reduce((acc: any, obj: any) => {
          const companyId = obj?.p_company?.id; // Added nullish coalescing for obj.p_company.id
          if (!acc[companyId]) {
            acc[companyId] = {
              id: companyId,
              p_company: obj?.p_company, // Added nullish coalescing for obj.p_company
              workx: [],
            };
          }
          acc[companyId].workx.push(obj);
          return acc;
        }, {})
      )) ??
    [];

  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [selectedExperienceID, setSelectedExperienceID] = useState<any>();

  // p_company.practice_areas

  // area of practice
  const [practice, setPractice] = useState("");
  const [isPracticeActive, setIsPracticeActive] = useState(false);
  const practiceRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(practiceRef, () => {
    setIsPracticeActive(false);
    setPractice("");
  });
  const handleFocus = () => {
    setErrors((prev: any) => ({
      ...prev,
      p_company: {
        ...prev?.p_company,
        practice_areas: [""],
      },
    }));
    setIsPracticeActive(true);
  };

  const handleAddPractice = (selectedPractice: string) => {
    setErrors((prev: any) => ({
      ...prev,
      p_company: {
        ...prev?.p_company,
        practice_areas: [""],
      },
    }));
    setIsPracticeActive(false);
    if (!selectedPractice) return;

    if (formData.p_company?.practice_areas?.length === 3) {
      setErrors((prev: any) => ({
        ...prev,
        p_company: {
          ...prev?.p_company,
          practice_areas: ["List can not contain more than 3 items."],
        },
      }));
      return;
    }

    setFormData((prev: any) => {
      const updatedPracticeAreas: any = new Set([
        ...(prev?.p_company?.practice_areas || []),
        selectedPractice,
      ]); // Using a Set to ensure uniqueness
      return {
        ...prev,
        p_company: {
          ...prev?.p_company,
          practice_areas: [...updatedPracticeAreas],
        },
      };
    });

    setPractice("");
  };

  const removePractice = (item: any) => {
    setErrors((prev: any) => ({
      ...prev,
      p_company: {
        ...prev?.p_company,
        practice_areas: [""],
      },
    }));
    const updatedList = formData?.p_company?.practice_areas.filter(
      (el: any) => el !== item
    );
    setFormData((prev: any) => ({
      ...prev,
      p_company: {
        ...prev?.p_company,
        practice_areas: updatedList,
      },
    }));
  };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        if (practice) {
          handleAddPractice(practice);
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [practice, formData?.p_company?.practice_areas]);

  // p_company?.profile_picture
  const businessLogoList = useMemo(
    () =>
      formData?.p_company?.profile_picture
        ? [formData?.p_company?.profile_picture]
        : [],
    [formData?.p_company?.profile_picture]
  );

  // p_company?.biz_city p_company.biz_state
  const [cities, setCities] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  useEffect(() => {
    stateList.map((state: any) => {
      setStates((prev: any) => [
        ...prev,
        {
          label: state?.name,
          value: state?.state_code,
        },
      ]);
    });
  }, [stateList]);

  useEffect(() => {
    setCities([]);

    const selectedState: any = stateList.filter((state: any) => {
      if (state.state_code === formData?.p_company?.biz_state) return state;
    });
    selectedState[0]?.cities?.map((city: any) => {
      setCities((prev: any) => [
        ...prev,
        {
          label: city,
          value: city,
        },
      ]);
    });
  }, [formData?.p_company?.biz_state, stateList]);

  // Save button functionality

  const handleSave = async (query: any) => {
    const payload = JSON.parse(JSON.stringify(formData));
    removeExtraSpacesfromObject(payload);
    delete payload?.customer;
    if (payload?.p_individual?.id) {
      delete payload?.p_individual?.id;
    } else if (payload?.p_company?.id) {
      delete payload?.p_company?.id;
    }

    if (payload?.p_company?.social_phone) {
      payload.p_company.social_phone =
        countryCode + payload?.p_company?.social_phone;
    }
    // biz_gst_doc
    if (payload?.p_company?.biz_gst_doc) {
      const url = payload?.p_company?.biz_gst_doc;
      const endIdx = url.indexOf("?");
      let extractedPart;

      if (endIdx !== -1) {
        extractedPart = url.substring(url.indexOf("user-docs/"), endIdx);
      } else {
        extractedPart = url.substring(url.indexOf("user-docs/"));
      }
      payload.p_company.biz_gst_doc = extractedPart;
    }
    // bank_cancel_chq_doc
    if (payload?.p_company?.bank_cancel_chq_doc) {
      const url = payload?.p_company?.bank_cancel_chq_doc;
      const endIdx = url.indexOf("?");
      let extractedPart;

      if (endIdx !== -1) {
        extractedPart = url.substring(url.indexOf("user-docs/"), endIdx);
      } else {
        extractedPart = url.substring(url.indexOf("user-docs/"));
      }
      payload.p_company.bank_cancel_chq_doc = extractedPart;
    }
    if (payload?.p_company?.social_fb) {
      payload.p_company.social_fb = validateHTTPURL(
        payload.p_company.social_fb
      );
    }
    if (payload?.p_company?.social_insta) {
      payload.p_company.social_insta = validateHTTPURL(
        payload.p_company.social_insta
      );
    }
    if (payload?.p_company?.social_linkedin) {
      payload.p_company.social_linkedin = validateHTTPURL(
        payload.p_company.social_linkedin
      );
    }
    if (payload?.p_company?.social_website) {
      payload.p_company.social_website = validateHTTPURL(
        payload.p_company.social_website
      );
    }
    if (payload?.p_company?.social_x) {
      payload.p_company.social_x = validateHTTPURL(payload.p_company.social_x);
    }

    if (professionalType === "employee") {
      delete payload?.p_company;
      delete formData?.p_company;
      payload.p_company = {};
    }

    if (professionalID) {
      setPageLoading(true);
      await protectedAxiosInstance
        .put(`/admin/accounts/professional/${professionalID}`, payload)
        .then(async () => {
          setErrors({});

          if (query !== "save") {
            protectedAxiosInstance
              .post(
                `/admin/accounts/professional/${professionalID}/status/change`,
                {
                  status:
                    query === "re-submit"
                      ? "CHANGE_REQUESTED"
                      : query === "reject"
                      ? "REJECTED"
                      : query === "verify"
                      ? "VERIFIED"
                      : query === "draft"
                      ? "DRAFT"
                      : "",
                  comment: removeExtraSpaces(submitData?.comment) || "",
                }
              )
              .then(async (res) => {
                setErrors({});
                setSubmitData(null);
                setOpenSubmitModal(null);
                await Promise.all([
                  fetchProfessionalData(),
                  fetchStatusHistory(),
                ]);
                toast.success("Status changed successfully.");
              })
              .catch((error) => {
                if (
                  error?.data?.errors?.p_company?.[0] ===
                    "Missing data for required field." ||
                  error?.data?.errors?.p_company?.[0] ===
                    "Field may not be null."
                ) {
                  toast.error("Please fill the company details.");
                } else if (
                  error?.data?.errors?.p_individual?.[0] ===
                    "Missing data for required field." ||
                  error?.data?.errors?.p_individual?.[0] ===
                    "Field may not be null."
                ) {
                  toast.error("Please fill the employee details.");
                }

                if (error?.data?.errors?._schema?.length === 1) {
                  toast.error(error?.data?.errors?._schema?.[0]);
                } else if (error?.data?.errors?._schema?.length > 1) {
                  toast.error(
                    <ul className="list-disc pl-4">
                      {error?.data?.errors?._schema?.map(
                        (errorMessage: any, i: number) => (
                          <li key={i}>{errorMessage}</li>
                        )
                      )}
                    </ul>
                  );
                }
                if (error?.data?.errors?.general?.length === 1) {
                  toast.error(error?.data?.errors?.general?.[0]);
                } else if (error?.data?.errors?.general?.length > 1) {
                  toast.error(
                    <ul className="list-disc pl-4">
                      {error?.data?.errors?.general?.map(
                        (errorMessage: any, i: number) => (
                          <li key={i}>{errorMessage}</li>
                        )
                      )}
                    </ul>
                  );
                }

                // if (
                //   !(
                //     error?.data?.errors?.p_individual?.[0] ===
                //       "Missing data for required field." ||
                //     error?.data?.errors?.p_individual?.[0] ===
                //       "Field may not be null." ||
                //     error?.data?.errors?.p_company?.[0] ===
                //       "Missing data for required field." ||
                //     error?.data?.errors?.p_company?.[0] ===
                //       "Field may not be null." ||
                //     error?.data?.errors?._schema?.length ||
                //     error?.data?.errors?.general?.length
                //   )
                // ) {
                //   toast.error("Error occured in saving professional data!");
                // }

                let toastError = "";
                if (
                  error?.data?.errors?._p_individual?.[0] !==
                    "Missing data for required field." ||
                  error?.data?.errors?.p_individual?.[0] !==
                    "Field may not be null."
                ) {
                  toastError = error?.data?.errors?.p_individual?.[0];
                }
                if (
                  error?.data?.errors?.p_company?.[0] !==
                    "Missing data for required field." ||
                  error?.data?.errors?.p_company?.[0] !==
                    "Field may not be null."
                ) {
                  toastError = error?.data?.errors?.p_company?.[0];
                }
                if (
                  error?.data?.errors?.schema?.length ||
                  error?.data?.errors?.general?.length
                ) {
                  toastError = "";
                }

                if (toastError) {
                  toast.error(toastError);
                }

                setErrors(error?.data?.errors);
                handleBackendError(error?.data?.errors);
              })
              .finally(() => {
                setPageLoading(false);
              });
          } else {
            await fetchProfessionalData();
            setPageLoading(false);
            toast.success("Professional data updated successfully!");
          }
        })
        .catch((error: any) => {
          setPageLoading(false);
          setSubmitData(null);
          setOpenSubmitModal(null);
          if (
            error?.data?.errors?.p_company?.[0] ===
              "Missing data for required field." ||
            error?.data?.errors?.p_company?.[0] === "Field may not be null."
          ) {
            toast.error("Please fill the company details.");
          } else if (
            error?.data?.errors?.p_individual?.[0] ===
              "Missing data for required field." ||
            error?.data?.errors?.p_individual?.[0] === "Field may not be null."
          ) {
            toast.error("Please fill the employee details.");
          }

          if (error?.data?.errors?._schema?.length === 1) {
            toast.error(error?.data?.errors?._schema?.[0]);
          } else if (error?.data?.errors?._schema?.length > 1) {
            toast.error(
              <ul className="list-disc pl-4">
                {error?.data?.errors?._schema?.map(
                  (errorMessage: any, i: number) => (
                    <li key={i}>{errorMessage}</li>
                  )
                )}
              </ul>
            );
          }
          if (error?.data?.errors?.general?.length === 1) {
            toast.error(error?.data?.errors?.general?.[0]);
          } else if (error?.data?.errors?.general?.length > 1) {
            toast.error(
              <ul className="list-disc pl-4">
                {error?.data?.errors?.general?.map(
                  (errorMessage: any, i: number) => (
                    <li key={i}>{errorMessage}</li>
                  )
                )}
              </ul>
            );
          }
          // if (
          //   !(
          //     error?.data?.errors?.p_individual?.[0] ===
          //       "Missing data for required field." ||
          //     error?.data?.errors?.p_individual?.[0] ===
          //       "Field may not be null." ||
          //     error?.data?.errors?.p_company?.[0] ===
          //       "Missing data for required field." ||
          //     error?.data?.errors?.p_company?.[0] ===
          //       "Field may not be null." ||
          //     error?.data?.errors?._schema?.length ||
          //     error?.data?.errors?.general?.length
          //   )
          // ) {
          //   toast.error("Error occured in saving professional data!");
          // }
          let toastError = "";
          if (
            error?.data?.errors?._p_individual?.[0] !==
              "Missing data for required field." ||
            error?.data?.errors?.p_individual?.[0] !== "Field may not be null."
          ) {
            toastError = error?.data?.errors?.p_individual?.[0];
          }
          if (
            error?.data?.errors?.p_company?.[0] !==
              "Missing data for required field." ||
            error?.data?.errors?.p_company?.[0] !== "Field may not be null."
          ) {
            toastError = error?.data?.errors?.p_company?.[0];
          }
          if (
            error?.data?.errors?.schema?.length ||
            error?.data?.errors?.general?.length
          ) {
            toastError = "";
          }

          if (toastError) {
            toast.error(toastError);
          }

          setErrors(error?.data?.errors);
          handleBackendError(error?.data?.errors);
        })
        .finally(() => {
          if (query === "save") {
            setPageLoading(false);
          }
        });
    }
  };

  // Company list api get request
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null); //eslint-disable-line
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLength, setPageLength] = useState(200);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyList, setCompanyList] = useState([]);

  // Effect to debounce the search term
  useEffect(() => {
    // Clear the previous timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout to update the debounced search term
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 400); // Adjust the delay here (e.g., 300 milliseconds)

    // Cleanup function
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfessionalList({
          search: debouncedSearchTerm || "",
          page: currentPage.toString() || "",
          length: pageLength.toString() || "",
          kind: "p_company",
        });
        setCompanyList(response?.data?.professional);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, pageLength]);

  const [employeeModal, setEmployeeModal] = useState(false);

  if (notFound) {
    return <Notfound />;
  }

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* Heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4 flex flex-col gap-2 ">
        <div className="flex items-center justify-between ">
          <p className="flex items-center gap-2 text-xl">
            <Title
              text={formData?.customer?.name}
              variant="secondary"
              size="xl"
            />
            <span
              className={`text-black  rounded-md text-sm m-0.5 px-3 py-0.5 cursor-pointer border-none ${
                formData?.customer?.p_status === "DRAFT"
                  ? "bg-[#EDEDED]"
                  : formData?.customer?.p_status === "IN_REVIEW"
                  ? "bg-[#FFC5EF]"
                  : formData?.customer?.p_status === "VERIFIED"
                  ? "bg-[#B6FDC6]"
                  : formData?.customer?.p_status === "REJECTED"
                  ? "bg-[#FFB4A1]"
                  : formData?.customer?.p_status === "CHANGE_REQUESTED"
                  ? "bg-[#BFB2FF]"
                  : formData?.customer?.p_status ===
                    "AWAITING_EXPERIENCE_APPROVAL"
                  ? "bg-[#FFDCB2]"
                  : ""
              }`}
            >
              {formData?.customer?.p_status.replace(/_/g, " ")}
            </span>
          </p>
        </div>
        <div className="flex gap-3 items-center ">
          {links?.employee_link &&
            formData?.customer?.p_status === "VERIFIED" && (
              <div className="rounded-md flex items-center gap-2 bg-[#FBF3E8] text-xs py-1 px-2 text-[#1C1C1C]">
                <EmployeeIcon />
                <Link
                  className="underline underline-offset-2 font-gilroy-regular leading-[20px]"
                  to={`${process.env.REACT_APP_DOMAIN}/i/${links?.employee_link}`} //eslint-disable-line
                  target="_blank"
                >
                  {/*  eslint-disable */}
                  {process.env.REACT_APP_DOMAIN}/i/{links?.employee_link}
                  {/*  eslint-disable */}
                </Link>
              </div>
            )}
          {links?.company_link &&
            formData?.customer?.p_status === "VERIFIED" && (
              <div className="rounded-md flex items-center gap-2 bg-[#FBF3E8] text-xs py-1 px-2 text-[#1C1C1C]">
                <CompanyIcon />{" "}
                <Link
                  className="underline underline-offset-2 font-gilroy-regular leading-[20px]"
                  to={`${process.env.REACT_APP_DOMAIN}/c/${links?.company_link}`} //eslint-disable-line
                  target="_blank"
                >
                  {/*  eslint-disable */}
                  {process.env.REACT_APP_DOMAIN}/c/{links?.company_link}
                  {/*  eslint-disable */}
                </Link>
              </div>
            )}
        </div>
      </div>

      <div className="flex w-full justify-between flex-1 h-[60dvh] overflow-y-clip  py-3 ">
        {showExperienceModal && (
          <CreateEditExperience
            setShowExperienceModal={setShowExperienceModal}
            selectedXp={selectedExperienceID}
            setSelectedXp={setSelectedExperienceID}
            reRender={() => {
              fetchWorkExperience().then(() => {
                fetchStatusHistory();
                fetchProfessionalData();
              });
            }}
            professionalID={professionalID}
            companyCategoriesOptions={companyCategoriesOptions}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            companyList={companyList}
            setCompanyList={setCompanyList}
          />
        )}
        {employeeModal && (
          <Modal
            top
            header="Change to Employee"
            handleCancel={() => {
              setEmployeeModal(false);
            }}
            footer={
              <div className="flex gap-4">
                <Button
                  label="Confirm"
                  onClick={() => {
                    setProfessionalType(
                      professionalType === "employee" ? "owner" : "employee"
                    );
                    setEmployeeModal(false);
                  }}
                />
                <Button
                  label="Cancel"
                  variant="secondary-outline"
                  onClick={() => {
                    setEmployeeModal(false);
                  }}
                />
              </div>
            }
          >
            <p className="font-gilroy-medium text-sm">
              Switching to employee profile will delete company profile.
            </p>
          </Modal>
        )}
        {openGSTModal && (
          <GSTPopup
            onChange={(e) => {}}
            handleClose={handleClose}
            gstDetails={gstDetails}
            // errorMessage={errorMessage.gst}
          />
        )}
        {(openSubmitModal === "re-submit" || openSubmitModal === "reject") && (
          <ReSubmitForm
            handleClose={() => {
              delete errors?.comment;
              setOpenSubmitModal(null);
              setSubmitData(null);
              setErrors({});
            }}
            loading={pageLoading}
            openSubmitModal={openSubmitModal}
            submitData={submitData}
            setSubmitData={setSubmitData}
            handleReSubmitForm={() =>
              openSubmitModal === "re-submit"
                ? handleSave("re-submit")
                : openSubmitModal === "reject"
                ? handleSave("reject")
                : () => {}
            }
            errors={errors}
            setErrors={setErrors}
          />
        )}

        {/* Left side section */}
        <div className="w-[60%] overflow-y-auto custom-scrollbar px-4">
          {/* p_individual */}
          <div ref={individual_ref}>
            <div className="my-4 flex flex-col gap-4">
              <Title text="Professional Type Selection" size="xl" />
              <div>
                <label className="font-gilroy-semi-bold text-[12px] pb-2  flex justify-start">
                  Type of Professional
                </label>
                <div className="flex gap-4">
                  <div
                    className={`${
                      pageLoading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (pageLoading) return;
                      if (professionalType === "employee") {
                        return;
                      } else {
                        setEmployeeModal(true);
                      }
                    }}
                  >
                    <Checkbox
                      toggleOff
                      borderType="square"
                      label="Employee"
                      checkValue={professionalType === "employee" ? 1 : 0}
                      disabled={pageLoading}
                    />
                  </div>
                  <Checkbox
                    toggleOff
                    borderType="square"
                    label="Owner of a company"
                    checkValue={professionalType === "owner" ? 1 : 0}
                    onClick={() => setProfessionalType("owner")}
                    disabled={pageLoading}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 my-4">
              <Title text="Professional Profile" size="xl" />
              <div>
                <div className="flex flex-col gap-4">
                  <Title text="INFORMATION" size="md" />
                  <div>
                    <Label label="Full Name" />
                    <div className="flex gap-2">
                      <SelectField2
                        targetRef={individual_salutation_ref}
                        selectCSS="!min-w-[95.55px]"
                        options={ProfessionalSalutationOptions}
                        value={formData?.p_individual?.salutation || ""}
                        onChange={(e) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev?.p_individual,
                              salutation: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev?.p_individual,
                              salutation: null,
                            },
                          }));
                        }}
                        placeholder="Salutation"
                        hint={errors?.p_individual?.salutation}
                        disabled={pageLoading}
                      />
                      {/* p_individual.name */}
                      <InputField
                        targetRef={individual_name_ref}
                        type="text"
                        label=""
                        placeholder="full name"
                        value={formData?.p_individual?.name || ""}
                        onChange={(e) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev.p_individual,
                              name: e,
                            },
                          }));
                          if (!individualSlugFound && !individualSlugEdited) {
                            setFormData((prev: any) => ({
                              ...prev,
                              p_individual: {
                                ...prev?.p_individual,
                                slug: e.split(" ").join("-").toLowerCase(),
                              },
                            }));
                          }
                          setErrors((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev.p_individual,
                              name: [],
                            },
                          }));
                        }}
                        hint={errors?.p_individual?.name || ""}
                        disabled={pageLoading}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  {/* p_individual.profession_id */}
                  <SelectField2
                    targetRef={individual_profession_ref}
                    removeOption={() => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev.p_individual,
                          profession_id: "",
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev.p_individual,
                          profession_id: [],
                        },
                      }));
                    }}
                    options={professionOptions.map((el: any) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    label="Profession"
                    onChange={(e) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev.p_individual,
                          profession_id: e,
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev.p_individual,
                          profession_id: [],
                        },
                      }));
                    }}
                    value={formData?.p_individual?.profession_id || ""}
                    searchPlaceHolder="Search Profession"
                    hint={errors?.p_individual?.profession_id}
                    disabled={pageLoading}
                  />

                  {/*p_individual?.practice_location_city, formData?.p_individual?.practice_location_state */}
                  <SelectField2
                    autoComplete={"new-street-address"}
                    targetRef={individual_practice_location_ref}
                    label="Location of Practice "
                    searchPlaceHolder="Search city or state here"
                    totalPage={locationTotalPage}
                    page={locationPage || 1}
                    setPage={setLocationPage}
                    options={[
                      ...locationList,
                      {
                        value: `${formData?.p_individual?.practice_location_city}, ${formData?.p_individual?.practice_location_state}`,
                        label: `${
                          formData?.p_individual?.practice_location_city
                        }, ${
                          stateList.find(
                            (state: any) =>
                              state.state_code ===
                              formData?.p_individual?.practice_location_state
                          )?.name
                        }`,
                        discard: true,
                      },
                    ]}
                    filterLogic={(options) => {
                      return options?.filter(
                        (option: any) => option?.discard !== true
                      );
                    }}
                    externalSearch={locationSearch}
                    externalSetSearch={setLocationSearch}
                    value={
                      formData?.p_individual?.practice_location_city &&
                      formData?.p_individual?.practice_location_state
                        ? `${formData?.p_individual?.practice_location_city}, ${formData?.p_individual?.practice_location_state}`
                        : ""
                    }
                    onChange={(e: any) => {
                      const string = e.split(", ");
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          practice_location_city: string[0],
                          practice_location_state: string[1],
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          practice_location_city: [],
                          practice_location_state: [],
                        },
                      }));
                    }}
                    removeOption={() => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          practice_location_city: "",
                          practice_location_state: "",
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          practice_location_city: [],
                          practice_location_state: [],
                        },
                      }));
                    }}
                    hint={
                      errors?.p_individual?.practice_location_city ||
                      errors?.p_individual?.practice_location_state
                    }
                    disabled={pageLoading}
                  />

                  {/* p_individual.about */}
                  <TextArea
                    targetRef={individual_about_ref}
                    label="About you"
                    placeholder="Your Personal Story / Bio"
                    rows={3}
                    value={formData?.p_individual?.about || null}
                    onChange={(e: any) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          about: e,
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: { ...prev?.p_individual, about: [] },
                      }));
                    }}
                    hint={errors?.p_individual?.about || ""}
                    disabled={pageLoading}
                  />

                  {/* p_individual.profile_picture */}
                  <ProfileImageUpload
                    targetRef={individual_profile_picture_ref}
                    label="Profile Photo:"
                    subLabel="Kindly upload your professional photo."
                    kind="PI-PHOTO"
                    maxSize={20}
                    imageList={profilePictureList}
                    setter={(value) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          profile_picture: value as string,
                        },
                      }));
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          profile_picture: null,
                        },
                      }));
                    }}
                    removeError={() => {
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          profile_picture: null,
                        },
                      }));
                    }}
                    errors={errors?.p_individual?.profile_picture}
                    setErrors={(message: any) => {
                      setErrors((prev: any) => ({
                        ...prev,
                        p_individual: {
                          ...prev?.p_individual,
                          profile_picture: message,
                        },
                      }));
                    }}
                    disabled={pageLoading}
                  />

                  <div ref={individual_slug_ref}>
                    <p className="font-medium text-[12px] pb-1 mt-2">
                      Public Profile URL:
                    </p>
                    <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-start ">
                      <p className="text-[14px] sm:text-sm mt-2">
                        {process.env.REACT_APP_DOMAIN}/i/
                      </p>
                      <InputField
                        value={formData?.p_individual?.slug || ""}
                        onChange={(e: any) => {
                          setIndividualSlugEdited(true);
                          setFormData((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev?.p_individual,
                              slug: e?.toLowerCase(),
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_individual: {
                              ...prev?.p_individual,
                              slug: [],
                            },
                          }));
                        }}
                        hint={errors?.p_individual?.slug || ""}
                        placeholder="Enter your preferred username"
                        validate={validateTextNumberHyphen}
                        disabled={pageLoading}
                        maxLength={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flx flex my-2 gap-4 justify-between items-center"
              ref={work_experience_ref}
            >
              <Title text="WORK EXPERIENCE" size="md" />
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => setShowExperienceModal(true)}
              >
                <PlusIcon width="14" color="black" />
              </div>
            </div>

            {groupedWorkExperience?.length > 0 ? (
              groupedWorkExperience?.map((experience: any, i: any) => {
                return (
                  <div key={i} className="border-t flex flex-col mb-4">
                    <ExperienceCard
                      experience={experience}
                      setShowExperienceModal={setShowExperienceModal}
                      setSelectedXp={setSelectedExperienceID}
                      reRender={fetchWorkExperience}
                      data={formData}
                    />
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col gap-2 items-center justify-center">
                <h1 className="font-roboto-condensed font-bold text-lg">
                  No experience added yet!
                </h1>
                <p
                  className="text-pot-yellow font-gilroy-bold cursor-pointer"
                  onClick={() => setShowExperienceModal(true)}
                >
                  Add new experience?
                </p>
              </div>
            )}
          </div>

          {/* p_company */}
          <div ref={company_ref}>
            {professionalType === "owner" && (
              <div>
                <div className="w-[98%]">
                  <div className="flx flex-col my-4 gap-2">
                    <Title text="Company Profile" size="xl" />
                  </div>

                  <div className="flex flex-col gap-4">
                    <Title text="COMPANY INFORMATION" size="md" />
                    {/* p_company.name */}
                    <InputField
                      targetRef={company_name_ref}
                      label="Display Name"
                      placeholder="Brand Identity / Recognized Company Name"
                      value={formData?.p_company?.name || ""}
                      hint={errors?.p_company?.name || ""}
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            name: e,
                          },
                        }));
                        if (!companySlugFound && !companySlugEdited) {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              slug: e.split(" ").join("-").toLowerCase(),
                            },
                          }));
                        }
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            name: [],
                          },
                        }));
                      }}
                      disabled={pageLoading}
                      maxLength={50}
                    />

                    {/* p_company?.category_id */}
                    <SelectField2
                      targetRef={company_category_ref}
                      label="Company Category"
                      options={companyCategoriesOptions}
                      value={formData?.p_company?.category_id || ""}
                      searchPlaceHolder="Search company category"
                      onChange={(e) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            category_id: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            category_id: [],
                          },
                        }));
                      }}
                      removeOption={() => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            category_id: "",
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            category_id: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.category_id}
                      disabled={pageLoading}
                    />

                    {/*p_company?.practice_location_city,p_company?.practice_location_state  */}
                    <SelectField2
                      autoComplete={"new-street-address"}
                      targetRef={company_practice_location_ref}
                      label="Location of Business"
                      searchPlaceHolder="Search city or state here"
                      totalPage={businessLocationTotalPage}
                      page={businessLocationPage || 1}
                      setPage={setBusinessLocationPage}
                      options={[
                        ...businessLocationList,
                        {
                          value: `${formData?.p_company?.practice_location_city}, ${formData?.p_company?.practice_location_state}`,
                          label: `${
                            formData?.p_company?.practice_location_city
                          }, ${
                            stateList.find(
                              (state: any) =>
                                state.state_code ===
                                formData?.p_company?.practice_location_state
                            )?.name
                          }`,
                          discard: true,
                        },
                      ]}
                      filterLogic={(options) => {
                        return options?.filter(
                          (option: any) => option?.discard !== true
                        );
                      }}
                      externalSearch={businessLocationSearch}
                      externalSetSearch={setBusinessLocationSearch}
                      value={
                        formData?.p_company?.practice_location_city &&
                        formData?.p_company?.practice_location_state
                          ? `${formData?.p_company?.practice_location_city}, ${formData?.p_company?.practice_location_state}`
                          : ""
                      }
                      onChange={(e: any) => {
                        const string = e.split(", ");
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            practice_location_city: string[0],
                            practice_location_state: string[1],
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            practice_location_city: [],
                            practice_location_state: [],
                          },
                        }));
                      }}
                      removeOption={() => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            practice_location_city: "",
                            practice_location_state: "",
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            practice_location_city: [],
                            practice_location_state: [],
                          },
                        }));
                      }}
                      hint={
                        errors?.p_company?.practice_location_city ||
                        errors?.p_company?.practice_location_state
                      }
                      disabled={pageLoading}
                    />

                    {/* p_company?.establishment_year */}
                    <SelectField2
                      targetRef={company_establishment_year_ref}
                      label="Year of Establishment"
                      options={Array.from(
                        { length: 300 },
                        (_, index) => new Date().getFullYear() - index
                      ).map((el: any) => ({
                        value: el.toString(),
                        label: el.toString(),
                      }))}
                      searchPlaceHolder="Search year"
                      value={formData?.p_company?.establishment_year || ""}
                      onChange={(e) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            establishment_year: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            establishment_year: [],
                          },
                        }));
                      }}
                      removeOption={() => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            establishment_year: null,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            establishment_year: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.establishment_year}
                      disabled={pageLoading}
                    />

                    {/* p_company?.practice_areas */}

                    <div className="relative" ref={practiceRef}>
                      <InputField
                        targetRef={company_practice_areas_ref}
                        placeholder="Search your practice area"
                        label="Area of Expertise:"
                        value={practice}
                        onChange={(e) => {
                          setPractice(e && removeExtraSpaces(e));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              practice_areas: null,
                            },
                          }));
                        }}
                        hint={errors?.p_company?.practice_areas}
                        disabled={pageLoading}
                        onFocus={handleFocus}
                        rightIcon={
                          practice && (
                            <div className="border-2 border-gray-300 px-2 hover:border-transparent hover:bg-black hover:text-white font-gilroy-medium text-sm rounded-md">
                              Press Enter
                            </div>
                          )
                        }
                        rightOnClick={() => handleAddPractice(practice)}
                      />
                      {isPracticeActive && (
                        <div
                          className={` w-full bg-white rounded-md shadow-md mt-1 z-50 text-start overflow-y-auto custom-scrollbar  absolute top-[100%] max-h-[150px]`}
                        >
                          {practiceAreaOptions
                            ?.filter((el: any) =>
                              el.label
                                .toLowerCase()
                                .includes(practice.toLowerCase())
                            )
                            ?.map((el: any, i: number) => (
                              <p
                                key={i}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddPractice(el.label);
                                }}
                                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular text-[14px] leading-5 block  ${
                                  el.label === practice ? "bg-gray-100" : ""
                                }`}
                              >
                                {el.label}
                              </p>
                            ))}
                        </div>
                      )}
                    </div>

                    {formData?.p_company?.practice_areas &&
                      formData?.p_company?.practice_areas.length > 0 && (
                        <div className="border-2 border-c-gray-2 p-2 flex gap-2 flex-wrap text-xs md:text-sm">
                          {formData?.p_company?.practice_areas?.map(
                            (el: any, i: any) => {
                              return (
                                <div
                                  key={i}
                                  className="flex items-center bg-[#ECECEC] font-gilroy-regular text-[#1C1C1C] text-[14px] leading-[20px] border border-[#ECECEC] px-2 py-1 gap-2 bg-opacity-60"
                                >
                                  <p
                                    className={` ${
                                      pageLoading
                                        ? "cursor-not-allowed opacity-50 "
                                        : ""
                                    } `}
                                  >
                                    {el}
                                  </p>

                                  {!pageLoading && (
                                    <div
                                      className="cursor-pointer p-2"
                                      onClick={() => removePractice(el)}
                                    >
                                      <CrossIcon color="black" width="10" />
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}

                    {/* p_company?.about */}
                    <TextArea
                      targetRef={company_about_ref}
                      label="About"
                      placeholder="Company Background & Overview"
                      rows={3}
                      value={formData?.p_company?.about || null}
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            about: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            about: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.about || ""}
                      disabled={pageLoading}
                    />
                    {/* p_company.profile_picture */}
                    <ProfileImageUpload
                      targetRef={company_profile_picture_ref}
                      kind="PC-LOGO"
                      label="Logo: "
                      maxSize={20}
                      imageList={businessLogoList}
                      setter={(value) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            profile_picture: value as string,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            profile_picture: null,
                          },
                        }));
                      }}
                      removeError={() => {
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            profile_picture: null,
                          },
                        }));
                      }}
                      errors={
                        errors?.p_company?.profile_picture
                          ? [errors?.p_company?.profile_picture]
                          : []
                      }
                      setErrors={(message: any) => {
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            profile_picture: message,
                          },
                        }));
                      }}
                      disabled={pageLoading}
                    />
                    {/* p_company.slug */}
                    <div ref={company_slug_ref}>
                      <p className="font-medium text-[12px] pb-2 mt-2">
                        Public Profile URL:
                      </p>
                      <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-start ">
                        <p className="text-[14px] sm:text-sm mt-2">
                          {process.env.REACT_APP_DOMAIN}/c/
                        </p>
                        <InputField
                          value={formData?.p_company?.slug || ""}
                          onChange={(e: any) => {
                            setCompanySlugEdited(true);
                            setFormData((prev: any) => ({
                              ...prev,
                              p_company: {
                                ...prev?.p_company,
                                slug: e?.toLowerCase(),
                              },
                            }));
                            setErrors((prev: any) => ({
                              ...prev,
                              p_company: {
                                ...prev?.p_company,
                                slug: [],
                              },
                            }));
                          }}
                          hint={errors?.p_company?.slug || ""}
                          placeholder="Enter your preferred username"
                          validate={validateTextNumberHyphen}
                          disabled={pageLoading}
                          maxLength={50}
                        />
                      </div>
                    </div>

                    {/* p_company.owner_title */}
                    <SelectField2
                      targetRef={company_owner_title_ref}
                      label="Your position in the company"
                      options={partnerOptions}
                      searchPlaceHolder="CEO / COO / Founder / Others"
                      value={formData?.p_company?.owner_title || ""}
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            owner_title: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            owner_title: [],
                          },
                        }));
                      }}
                      removeOption={() => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            owner_title: null,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            owner_title: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.owner_title || ""}
                      disabled={pageLoading}
                    />
                  </div>

                  <div className="flex flex-col gap-4 my-4">
                    <Title
                      text="SOCIAL AND PUBLIC CONTACT INFORMATION"
                      size="md"
                    />
                    <div className="flex flex-col gap-4">
                      {/* p_company?.social_fb */}
                      <InputField
                        targetRef={company_social_fb_ref}
                        label="Facebook Link:"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_fb: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_fb: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_fb || ""}
                        value={formData?.p_company?.social_fb || ""}
                        placeholder="Enter your facebook link"
                        leftIcon={<FacebookIcon />}
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                      />
                      {/* p_company?.social_x */}
                      <InputField
                        targetRef={company_social_x_ref}
                        label="X Link:"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_x: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_x: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_x || ""}
                        value={formData?.p_company?.social_x || ""}
                        placeholder="Enter your X link"
                        leftIcon={<XIcon />}
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                      />
                      {/* p_company.social_insta */}
                      <InputField
                        targetRef={company_social_insta_ref}
                        label="Instagram Link:"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_insta: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_insta: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_insta || ""}
                        value={formData?.p_company?.social_insta || ""}
                        placeholder="Enter your instagram link"
                        leftIcon={<InstagramIcon />}
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                      />
                      {/* p_company?.social_linkedin */}
                      <InputField
                        targetRef={company_social_linkedin_ref}
                        label="Linkedin Link:"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_linkedin: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_linkedin: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_linkedin || ""}
                        value={formData?.p_company?.social_linkedin || ""}
                        placeholder="Enter your linkedin link"
                        leftIcon={<LinkedInIcon />}
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                      />
                      {/* p_company?.social_website */}
                      <InputField
                        targetRef={company_social_website_ref}
                        label="Website Link:"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_website: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_website: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_website || ""}
                        value={formData?.p_company?.social_website || ""}
                        placeholder="Enter your website link"
                        leftIcon={<WebsiteIcon />}
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                      />
                      {/* p_company?.social_email */}
                      <InputField
                        autoComplete={"new-email"}
                        targetRef={company_social_email_ref}
                        label="Email ID:"
                        onChange={(e: any) => {
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_email: "",
                            },
                          }));
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              social_email: e,
                            },
                          }));
                        }}
                        hint={errors?.p_company?.social_email || ""}
                        value={formData?.p_company?.social_email || ""}
                        placeholder="Enter your company email id"
                        disabled={pageLoading}
                        validate={validateSpaceNotAllowed}
                        infoIcon={true}
                        infoMessage="This information will be displayed publicly on your profile"
                      />
                    </div>
                    <AuthInputField
                      targetRef={company_social_phone_ref}
                      country_code_value={countryCode}
                      setCode={(countryCode: any) =>
                        setCountryCode(countryCode)
                      }
                      value={formData?.p_company?.social_phone || ""}
                      placeholder="XXXXXXXXXX"
                      heading="Phone Number:"
                      name="contact"
                      onChange={(e: any) => {
                        const value = e;
                        const numericValue = value.replace(/\D/g, "");

                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            social_phone: numericValue,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            social_phone: [],
                          },
                        }));
                      }}
                      type="telephone"
                      hint={errors?.p_company?.social_phone || ""}
                      disabled={pageLoading}
                      maxLength={10}
                      infoIcon={true}
                      infoMessage="This information will be displayed publicly on your profile"
                      onWheel={(e: any) => e.preventDefault()} // Add this line to prevent scrolling
                    />
                  </div>

                  <div className="flex flex-col gap-4 my-4 ">
                    <Title text="KYC INFORMATION" size="xl" />

                    <div className="flex flex-col gap-4">
                      <Title text="Company Information" size="md" />
                      {/* p_company.biz_legal_name */}
                      <InputField
                        targetRef={company_biz_legal_name_ref}
                        label="Business Legal Name"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_legal_name: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_legal_name: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.biz_legal_name || ""}
                        value={formData?.p_company?.biz_legal_name || ""}
                        placeholder="Enter business legal name"
                        disabled={pageLoading}
                        maxLength={50}
                      />

                      {/* p_company?.biz_type_id */}
                      <SelectField2
                        targetRef={company_biz_type_ref}
                        label="Business Type"
                        options={businessTypeOptions}
                        value={formData?.p_company?.biz_type_id || ""}
                        searchPlaceHolder="Search business type"
                        onChange={(e) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_type_id: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_type_id: [],
                            },
                          }));
                        }}
                        removeOption={() => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_type_id: "",
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_type_id: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.biz_type_id}
                        disabled={pageLoading}
                      />

                      {/* p_company?.biz_about */}
                      <TextArea
                        targetRef={company_biz_about_ref}
                        label="About Your Business"
                        placeholder="Tell us about your business"
                        rows={3}
                        value={formData?.p_company?.biz_about || ""}
                        hint={errors?.p_company?.biz_about}
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_about: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_about: [],
                            },
                          }));
                        }}
                        disabled={pageLoading}
                      />

                      {/* p_company?.biz_pan_no */}
                      <InputField
                        targetRef={company_biz_pan_no_ref}
                        label="Authorized Signatory PAN"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_pan_no: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_pan_no: [],
                            },
                          }));
                        }}
                        onBlur={(e: any) => {
                          if (!checkPAN(e) && e) {
                            setErrors((prev: any) => ({
                              ...prev,
                              p_company: {
                                ...prev?.p_company,
                                biz_pan_no: ["Not a valid pan number."],
                              },
                            }));
                          }
                        }}
                        hint={errors?.p_company?.biz_pan_no || ""}
                        value={formData?.p_company?.biz_pan_no || ""}
                        placeholder="Enter PAN number"
                        disabled={pageLoading}
                        maxLength={50}
                      />

                      {/* p_company?.biz_pan_name */}
                      <InputField
                        targetRef={company_biz_pan_name_ref}
                        label="PAN Owner Name"
                        onChange={(e: any) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_pan_name: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_pan_name: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.biz_pan_name || ""}
                        value={formData?.p_company?.biz_pan_name || ""}
                        placeholder="Enter PAN Owner Name"
                        disabled={pageLoading}
                        maxLength={50}
                      />
                    </div>
                  </div>
                </div>
                {/* p_company?.biz_gst_no */}
                <div className="flex items-start gap-2 w-[100%]">
                  <div className="w-[80%] ">
                    <InputField
                      targetRef={company_biz_gst_no_ref}
                      label="GST Number"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_gst_no: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_gst_no: [],
                          },
                        }));
                      }}
                      onBlur={(e: any) => {
                        if (!checkGST(e) && e) {
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_gst_no: ["Not a valid gst number."],
                            },
                          }));
                        }
                      }}
                      hint={errors?.p_company?.biz_gst_no || ""}
                      value={formData?.p_company?.biz_gst_no || ""}
                      placeholder="GST Number"
                      disabled={pageLoading}
                      maxLength={15}
                    />
                  </div>
                  <div className="text-pot-yellow text-sm whitespace-nowrap mt-7">
                    <Button
                      fullWidth
                      label={"Get GST details"}
                      variant="secondary-text"
                      onClick={() => {
                        setGSTOpenModal(true);
                        handleGSTDetails(formData?.p_company?.biz_gst_no);
                      }}
                    />
                  </div>
                </div>

                <div className=" w-[98%] my-4">
                  {/* p_company?.biz_gst_doc */}
                  <UploadFile
                    targetRef={company_biz_gst_doc_ref}
                    label="GST Certificate"
                    kind="PC-GSTC"
                    inputID="file-upload-gst"
                    value={formData?.p_company?.biz_gst_doc}
                    setter={(value: any) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        p_company: {
                          ...prev?.p_company,
                          biz_gst_doc: value as string,
                        },
                      }));
                    }}
                    removeError={() => {
                      if (errors?.p_company?.biz_gst_doc) {
                        delete errors?.p_company?.biz_gst_doc;
                      }
                    }}
                    error={errors?.p_company?.biz_gst_doc}
                    setErrors={(message: any) => {
                      setErrors((prev: any) => ({
                        ...prev,
                        p_company: {
                          ...prev?.p_company,
                          biz_gst_doc: message,
                        },
                      }));
                    }}
                    disabled={pageLoading}
                  />

                  <div className="flex flex-col gap-4 my-4">
                    <Title text="BUSINESS OPERATIONAL ADDRESS" size="md" />
                    {/* p_company?.biz_street1 */}
                    <InputField
                      targetRef={company_biz_street1_ref}
                      label="Address line 1"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_street1: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_street1: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.biz_street1 || ""}
                      value={formData?.p_company?.biz_street1 || ""}
                      placeholder="Enter your address line 1"
                      disabled={pageLoading}
                      maxLength={100}
                    />
                    {/* p_company?.biz_street2 */}
                    <InputField
                      targetRef={company_biz_street2_ref}
                      label="Address line 2"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_street2: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_street2: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.biz_street2 || ""}
                      value={formData?.p_company?.biz_street2 || ""}
                      placeholder="Enter your address line 2"
                      disabled={pageLoading}
                      maxLength={50}
                    />

                    <div className="flex flex-col sm:flex-row gap-4 items-start [&>div]:flex-1">
                      {/* p_company?.biz_city */}
                      <SelectField2
                        autoComplete={"new-city"}
                        targetRef={company_biz_city_ref}
                        value={formData?.p_company?.biz_city || ""}
                        label="City:"
                        options={cities}
                        onChange={(e) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: e,
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: [],
                            },
                          }));
                        }}
                        removeOption={() => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: "",
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.biz_city}
                        searchPlaceHolder="Search city"
                        disabled={
                          pageLoading || !formData?.p_company?.biz_state
                        }
                      />

                      {/* p_company?.biz_state */}
                      <SelectField2
                        autoComplete={"new-state"}
                        targetRef={company_biz_state_ref}
                        value={formData?.p_company?.biz_state || ""}
                        label="State:"
                        options={states}
                        searchPlaceHolder="Search state"
                        onChange={(e) => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_state: e,
                            },
                          }));
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: "",
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_state: [],
                            },
                          }));
                        }}
                        removeOption={() => {
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_state: "",
                            },
                          }));
                          setFormData((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_city: "",
                            },
                          }));
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev.p_company,
                              biz_state: [],
                            },
                          }));
                        }}
                        hint={errors?.p_company?.biz_state}
                        disabled={pageLoading}
                      />
                    </div>
                    {/* p_company?.biz_zipcode */}
                    <InputField
                      autoComplete={"new-postal-code"}
                      targetRef={company_biz_zipcode_ref}
                      label="Pin Code:"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_zipcode: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            biz_zipcode: [],
                          },
                        }));
                      }}
                      onBlur={(e: any) => {
                        if (!checkPincode(e) && e) {
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              biz_zipcode: ["Not a valid pincode."],
                            },
                          }));
                        }
                      }}
                      hint={errors?.p_company?.biz_zipcode || ""}
                      value={formData?.p_company?.biz_zipcode || ""}
                      placeholder="Enter your pin code"
                      disabled={pageLoading}
                      maxLength={50}
                      type="number"
                    />
                  </div>

                  <div className="flex flex-col gap-4 my-4">
                    <Title text="BANK DETAILS" size="md" />
                    {/* p_company?.bank_beneficiary */}
                    <InputField
                      targetRef={company_bank_beneficiary_ref}
                      label="Beneficiary Name"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_beneficiary: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_beneficiary: [],
                          },
                        }));
                      }}
                      hint={errors?.p_company?.bank_beneficiary || ""}
                      value={formData?.p_company?.bank_beneficiary || ""}
                      placeholder="Enter benificiary name"
                      disabled={pageLoading}
                      maxLength={50}
                    />
                    {/*p_company?.bank_ifsc  */}
                    <InputField
                      targetRef={company_bank_ifsc_ref}
                      label="Branch IFSC Code"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_ifsc: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_ifsc: [],
                          },
                        }));
                      }}
                      onBlur={(e: any) => {
                        if (!checkIFSC(e) && e) {
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              bank_ifsc: ["Not a valid bank ifsc."],
                            },
                          }));
                        }
                      }}
                      hint={errors?.p_company?.bank_ifsc || ""}
                      value={formData?.p_company?.bank_ifsc || ""}
                      placeholder="Enter benificiary ifsc code"
                      disabled={pageLoading}
                      maxLength={50}
                    />
                    {/* p_company?.bank_acc_no */}
                    <InputField
                      targetRef={company_bank_acc_no_ref}
                      label="Account number:"
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_acc_no: e,
                          },
                        }));
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_acc_no: [],
                          },
                        }));
                      }}
                      onBlur={(e: any) => {
                        if (!checkBankAccountNumber(e) && e) {
                          setErrors((prev: any) => ({
                            ...prev,
                            p_company: {
                              ...prev?.p_company,
                              bank_acc_no: ["Not a valid bank account number."],
                            },
                          }));
                        }
                      }}
                      hint={errors?.p_company?.bank_acc_no || ""}
                      value={formData?.p_company?.bank_acc_no || ""}
                      placeholder="Enter account number"
                      disabled={pageLoading}
                      maxLength={50}
                    />
                    {/* p_company?.bank_cancel_chq_doc */}
                    <UploadFile
                      targetRef={company_bank_cancel_chq_doc_ref}
                      label="Upload Cancelled Cheque"
                      kind="PC-CCHEQUE"
                      inputID="file-upload-cc"
                      value={formData?.p_company?.bank_cancel_chq_doc}
                      setter={(value: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_cancel_chq_doc: value as string,
                          },
                        }));
                      }}
                      removeError={() => {
                        if (errors?.p_company?.bank_cancel_chq_doc) {
                          delete errors?.p_company?.bank_cancel_chq_doc;
                        }
                      }}
                      error={errors?.p_company?.bank_cancel_chq_doc}
                      setErrors={(message: any) => {
                        setErrors((prev: any) => ({
                          ...prev,
                          p_company: {
                            ...prev?.p_company,
                            bank_cancel_chq_doc: message,
                          },
                        }));
                      }}
                      disabled={pageLoading}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right side section */}
        <div className="w-[40%] px-4 flex flex-col gap-0">
          <ProfessionalHistory
            history={history}
            statusLoading={statusLoading}
          />
        </div>
      </div>

      {/* General Buttons */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          variant="primary"
          label="Save"
          onClick={() => {
            handleSave("save");
          }}
          disabled={pageLoading}
        />

        <Button
          variant="primary"
          disabled={
            pageLoading || formData?.customer?.p_status === "CHANGE_REQUESTED"
          }
          label="Ask for re-submit"
          onClick={() => {
            setOpenSubmitModal("re-submit");
          }}
        />

        <Button
          variant="primary"
          disabled={pageLoading || formData?.customer?.p_status === "VERIFIED"}
          label="Verify"
          onClick={() => {
            handleSave("verify");
          }}
        />

        <Button
          variant="secondary"
          disabled={pageLoading || formData?.customer?.p_status === "REJECTED"}
          label="Reject"
          onClick={() => {
            setOpenSubmitModal("reject");
          }}
        />

        <Button
          variant="secondary-outline"
          disabled={
            pageLoading ||
            formData?.customer?.p_status === "VERIFIED" ||
            formData?.customer?.p_status === "CHANGE_REQUESTED" ||
            formData?.customer?.p_status === "AWAITING_EXPERIENCE_APPROVAL" ||
            formData?.customer?.p_status === "IN_REVIEW"
          }
          label="Switch to draft"
          onClick={() => {
            handleSave("draft");
          }}
        />
      </div>
    </div>
  );
};

export default CreateEditProfessional;
